/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NavLogo from "../../assets/Image/NavLogo.svg";
import MenuLog from "../../assets/Icons/MenuLog.svg";
import MenuClose from "../../assets/Icons/MenuClose.svg";
import HamLogo from "../../assets/Icons/HamLogo.svg";
import HamRes from "../../assets/Icons/HamRes.svg";
import EngFlag from "../../assets/Icons/EngFlag.svg";
import FinFlag from "../../assets/Image/FinFlag.svg";
import Navigaiton from "./Navigaiton";
import { NavLink } from "react-router-dom";
import "../../utils/i18n";
import i18n from "../../utils/i18n";
// import { useTranslation } from "react-i18next";

const Header = ({ check, setcheck }) => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (check === false) {
      i18n.changeLanguage("en")
    }
    else if (check === true) {
      i18n.changeLanguage("fr")
    }

  }, [check])

  const navMenuEvent = () => {
    setOpenMenu(!openMenu);
  };
  const CloseMenu = () => {
    setOpenMenu(false);
  };
  return (
    <>
      <div className="navbar">
        <div className="navbar_center_Div">
          <div className="navLogoDiv">
            <img
              src={HamLogo}
              alt=""
              className="hmbgr_img"
              onClick={navMenuEvent}
            />
            <NavLink to="/">
              <img
                src={HamRes}
                alt=""
                className="Hambrgr_responsive"
                onClick={navMenuEvent}
              />
            </NavLink>
            <span>
              <NavLink to="/">
                <img src={NavLogo} alt="" className="navLogo" />
              </NavLink>
            </span>
            {
              window.location.pathname === "/" ?
                "" :
                <NavLink to="#">
                  {
                    check === true ? <><img src={EngFlag} alt="" className="navLogo_responsive" onClick={() => setcheck(!check)} /></>
                      : <> <img src={FinFlag} alt="" className="navLogo_responsive" onClick={() => setcheck(!check)} /></>
                  }

                </NavLink>
            }

          </div>
          <div className="nav_menu_div">
          </div>
        </div>
        <div className={openMenu ? "open_menu" : "navigation_manu"}>
          <div className="navigation_menu_center_div">
            <div className="nav_logo_close_btn_div">
              <NavLink to="/">
                <img src={MenuLog} alt="" className="menu_logo" />
              </NavLink>
              <img
                src={MenuClose}
                alt=""
                className="menu_logo2"
                onClick={CloseMenu}
              />
            </div>
            <Navigaiton />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
