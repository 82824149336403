import React from "react";
import "../../css/Driver/Fairness.css";
import Step1 from "../../assets/Icons/Step1.svg";
import Step2 from "../../assets/Icons/Step2.svg";
import Step3 from "../../assets/Icons/Step3.svg";
import Step4 from "../../assets/Icons/Step4.svg";
import Fairness2 from "../../assets/Image/Fairness2.svg"
import { useTranslation } from "react-i18next";
import Registration from "../../FormPopup/Registration";



const Fairness = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const openAplyForm = () => {
    setOpen(true);
  };
  return (
    <>
      <Registration open={open} setOpen={setOpen} />

      <div className="Fairness_main">
        <div className="Fairness_socond">
          {/* heading 1  */}
          <div className="Fairness_heading_1">
            <p className="Fairness_heading_1_title">
              {t("Reilu is all about Fairness")}
            </p>
            <p className="Fairness_heading_1_p">
              {t("Reilu is on mission of making food delivery business as a career job. As a Reilu driver, you will be part of Reilu's drivers' cooperative which provides fairer work contracts and commissions to the delivery partners that ensure sustainable profits for both drivers and restaurants.")}
            </p>
            <p className="Fairness_heading_1_tag">{t("Be your own boss")}</p>
            <button className="Fairness_heading_1_button" onClick={openAplyForm}>{t("Apply Now")}</button>
          </div>
          {/* heading 2  */}
          <div className="Fairness_heading_2_main">
            <div className="Fairness_heading_2">
              <div className="Fairness_heading_2_col ">
                <img
                  src={Step1}
                  alt=""
                  className="Fairness_heading_2_col_img"
                />
              </div>
              <div className="Fairness_heading_2_col2 ">
                <h1 className="Fairness_heading_2_title">
                  {t("Fill in your application")}
                </h1>
                <p className="Fairness_heading_2_p">
                  {t("In a matter of minutes, take the a step towards a better business deal")}
                </p>
              </div>
            </div>
          </div>
          {/* heading 3  */}
          <div className="Fairness_heading_3_main">
            <div className="Fairness_heading_3_socond">
              <div className="Fairness_heading_3_colmn">
                <div className="Fairness_heading_3">
                  <div className="Fairness_heading_3_col">
                    <img
                      src={Step3}
                      alt=""
                      className="Fairness_heading_3_col_img"
                    />
                  </div>
                  <div className="Fairness_heading_3_col2 ">
                    <h1 className="Fairness_heading_2_title">{t("Get ready to start")}</h1>
                    <p className="Fairness_heading_2_p">
                      {t("Get your delivery gear for free")}

                    </p>
                  </div>
                </div>
              </div>
              {/* heading 4  */}
              <div className="Fairness_heading_3_colmn2">
                <div className="Fairness_heading_4">
                  <div className="Fairness_heading_4_col ">
                    <img
                      src={Step2}
                      alt=""
                      className="Fairness_heading_4_col_img"
                    />
                  </div>
                  <div className="Fairness_heading_4_col2 ">
                    <h1 className="Fairness_heading_2_title">
                      {t("Learn the trade")}
                    </h1>
                    <p className="Fairness_heading_2_p">
                      {t("Get brief introduction on the job's requirements")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* heading 5  */}
          <div className="Fairness_heading_2_main">
            <div className="Fairness_heading_5">
              <div className="Fairness_heading_3_col">
                <img
                  src={Step4}
                  alt=""
                  className="Fairness_heading_2_col_img"
                />
              </div>
              <div className="Fairness_heading_5_col2 ">
                <h1 className="Fairness_heading_2_title">{t("Gear Up")}</h1>
                <p className="Fairness_heading_2_p">
                  {t("Gear up and start the deliveries")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* 2 ind div  */}
      <div className="container-fluid  Hear_Div  ">
        <div className="row">
          <div className="col-lx-5 col-lg-5 col-md-5 col-sm-12 col-12">
            <div className="Fairness_heading2 ">
              {t("Hear from our first batch of courier partners")}
            </div>
            <div className="Fairness_p2">
              {t("Reading through Reilu's courier terms, I'm excited to be a part of this organization. The team seems super friendly and I'm looking forward to a great partnership going forward [Basit]")}
            </div>
          </div>
          <div className="col-lx-7 col-lg-7 col-md-7 col-sm-12 col-12">
            <div className="Fairness_Image2">

              <img
                src={Fairness2}
                alt=""
                style={{ width: '94%', height: '100%' }} />
            </div>

          </div>
        </div>


      </div>


    </>
  );
};

export default Fairness;
