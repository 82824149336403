import React, { useEffect, useState } from "react";
import Header from "../components/Home/Header";
import BlogsSection from "../components/Blogs/BlogsSection";
import FoodBlogs from "../components/Blogs/FoodBlogs";
import JoinUs from "../components/Blogs/JoinUs";
import News from "../components/Blogs/News";
import Footer from "../components/Home/Footer";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  React.useState(false);

  const { i18n, } = useTranslation();
  const [check, setcheck] = useState(true)
  useEffect(() => {
    if (check === false) {
      i18n.changeLanguage("en")
    }
    else if (check === true) {
      i18n.changeLanguage("fr")
    }

  }, [check])

  const scrollToTop = () => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
  };
  React.useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <Header check={check} setcheck={setcheck}/>
      <BlogsSection />
      <FoodBlogs title="Story Behind the Food Blog" />
      <JoinUs />
      <News />
      <Footer check={check} setcheck={setcheck} />
    </>
  );
};

export default Blogs;
