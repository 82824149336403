import React, { useEffect, useState } from "react";
import Header from "../components/Home/Header";
import Form from "../components/ContactUs/Form";
import Support from "../components/ContactUs/Support";
import Footer from "../components/Home/Footer";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  React.useState(false);

  const { i18n, } = useTranslation();
  const [check, setcheck] = useState(true)
  useEffect(() => {
    if (check === false) {
      i18n.changeLanguage("en")
    }
    else if (check === true) {
      i18n.changeLanguage("fr")
    }

  }, [check])

  const scrollToTop = () => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
  };
  React.useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <Header check={check} setcheck={setcheck}/>
      <Form />
      <Support />
      <Footer check={check} setcheck={setcheck} />
    </>
  );
};

export default ContactUs;
