/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../css/Driver/DriverSection.css";
import DriverBg from "../../assets/Image/driverimgchange.svg";
import Registration from "../../FormPopup/Registration";
import { useTranslation } from "react-i18next";


const DriverSection = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const openAplyForm = () => {
    alert("to you")
    setOpen(true);
  };
  return (
    <>
      <Registration open={open} setOpen={setOpen} />
      <div className="DriverSection_main">
        <div className="DriverSection_socond">
          <div className="DriverSection_colmn">
            <div className="DriverSection_headigs  ">
              <p className="DriverSection_title ">
                {t("Fair deliveries Fair contracts")}
              </p>
              <p className="DriverSection_title_p">
                {t("Do not promise/bind us to this")}
              </p>
              <p className="DriverSection_btn_top_text">{t("Be your own boss")}</p>
              <button className="DriverSection_btn" onClick={openAplyForm}>
                {t("Apply Now")}
              </button>
            </div>
          </div>
          <div className="DriverSection_colmn2">
            <div className="DriverSection_image_div">
              <img src={DriverBg} alt="" className="DriverSection_bg_img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverSection;
