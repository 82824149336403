import React from "react";
import "../../css/Blogs/News.css";
import NewsCardImg from "../../assets/Image/NewsCardImg.jpg";
import BlogsImg2 from "../../assets/Image/BlogsImg2.jpg";
// card
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const CardData = [
  {
    title:
      "Reilu is a Turku-based startup that aims to provide a total customer",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provide a total customer-centric food delivery service. Fairness and quality service are paramount to us. Reilu is driven by passion to helpReilu is a Turku-based startup that aims to provide a total customer-centric food delivery service. Fairness and quality service are paramount to us. Reilu is driven by passion to helpRe",
    content2:
      "Reilu is a Turku-based startup that aims to provide a total customer-centric food delivery servi",
  },
];

const CardData2 = [
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
];

const News = () => {
  return (
    <>
      <div className="News_main_div">
        <div className="News_centerDiv">
          <h1 className="news_h1">News</h1>
          <div className="News_card_main_div">
            {/* firs card div  */}
            <div className="News_card_colms">
              {/* card  */}
              {CardData.map((item) => {
                return (
                  <Grid item sm={12} xs={12}>
                    {/* card  */}

                    <Card>
                      <CardMedia
                        className="News_cardImage"
                        component="img"
                        image={NewsCardImg}
                        alt="green iguana"
                        // className="Card_first_img"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="card_heading"
                        >
                          <div>
                            <p className="card_title">{item.title}</p>
                            <p className="card_title2">{item.title2}</p>
                          </div>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <p className="card_title_p"> {item.content}</p>
                          <p className="card_title_p2"> {item.content2}</p>
                        </Typography>
                      </CardContent>

                      <a href="#" className="card_xyz">
                        www.xyz.com
                      </a>
                    </Card>
                  </Grid>
                );
              })}
              {/* card  */}
            </div>

            {/* colms cards 2 div  */}
            <div className="News_card_colms2">
              {CardData2.map((item) => {
                return (
                  <>
                    <div className="news_small_colmns">
                      {/* start cards  */}
                      <Card className="News_cards">
                        <CardMedia
                          className="News_cards_images"
                          component="img"
                          image={NewsCardImg}
                          alt="green iguana"
                        />
                        <CardContent className="CardContent_card">
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="card_heading_2"
                          >
                            <div>
                              <p className="card_title_2">{item.title}</p>
                              <p className="card_title2">{item.title2}</p>
                            </div>
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <p className="card_title_p"> {item.content}</p>
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
