import React, { useEffect, useState } from "react";
import Header from "../components/Home/Header";
import AboutHome from "../components/About/AboutHome";
import WhatWeAre from "../components/About/WhatWeAre";
import Milestones from "../components/About/Milestones";
import OurBrilliant from "../components/About/OurBrilliant";
import AboutReilu from "../components/About/AboutReilu";
import Footer from "../components/Home/Footer";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  React.useState(false);

  const { i18n, } = useTranslation();
  const [check, setcheck] = useState(true)
  useEffect(() => {
    if (check === false) {
      i18n.changeLanguage("en")
    }
    else if (check === true) {
      i18n.changeLanguage("fr")
    }

  }, [check])

  const scrollToTop = () => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
  };
  React.useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <Header check={check} setcheck={setcheck} />
      <AboutHome />
      <WhatWeAre />
      {/* <Milestones /> */}
      <OurBrilliant />
      {/* <AboutReilu /> */}
      <Footer check={check} setcheck={setcheck} />
    </>
  );
};

export default AboutUs;
