import React from "react";
import "../../css/Blogs/JoinUs.css";

const JoinUs = () => {
  return (
    <>
      <div className="JoinUs_main_div">
        <div className="JoinUs_second_">
          {/* heading  */}
          <div className="JoinUs_headings">
            <h2 className="JoinUs_title">JOIN US TODAY!</h2>
            <p className="JoinUs_p">Receive the latest posts via email</p>
          </div>
          {/* input fields  */}
          <div className="JoinUs_input_div">
            <input
              type="text"
              className="userName_input"
              placeholder="Your name"
            />
            <input
              type="text"
              className="userName_input2"
              placeholder="Your email address"
            />
          </div>
          {/* button  */}
          <div className="JoinUse_btn_div">
            <button className="JoinUs_btn">Subscribe</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinUs;
