import React from "react";
import "../../css/Driver/ReiluDriver.css";
import WolletIcon from "../../assets/Icons/WolletIcon.svg";
import WatchIcon from "../../assets/Icons/WatchIcon.svg";
import GroupIcon from "../../assets/Icons/GroupIcon.svg";
import ReiluVideo from "../../assets/Image/driverimgchange2.svg";
import { useTranslation } from "react-i18next";


const ReiluDriver = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="ReiluDriver_main">
        <div className="ReiluDriver_second">
          {/* headings div  */}
          <div className="ReiluDriver_headings">
            <p className="ReiluDriver_title">{t("Become a Reilu Driver and")}..</p>
          </div>
          {/* clmn div  */}
          <div className="ReiluDriver_colmns_mein_div">
            <div className="ReiluDriver_col">
              <div className="ReiluDriver_col_content">
                <img src={WolletIcon} alt="" className="ReiluDriver_col_img" />
                <h2 className="ReiluDriver_col_h2">{t("Get a fair contract")}</h2>
                <p className="ReiluDriver_col_p">
                  {t("On top of the compensation you can also be a member of the cooperative company as an entrepreneur")}
                </p>
              </div>
            </div>
            <div className="ReiluDriver_col">
              <div className="ReiluDriver_col_content">
                <img src={WatchIcon} alt="" className="ReiluDriver_col_img" />
                <h2 className="ReiluDriver_col_h2">{t("Work when you want")}</h2>
                <p className="ReiluDriver_col_p">
                  {t("The hours are flexible. Even when you are working with employee status, you have freedom to work whenever suits you best.")}
                </p>
              </div>
            </div>
            <div className="ReiluDriver_col">
              <div className="ReiluDriver_col_content">
                <img src={GroupIcon} alt="" className="ReiluDriver_col_img" />
                <h2 className="ReiluDriver_col_h2">Be part of a community</h2>
                <p className="ReiluDriver_col_p">
                  {t("In Reilu we are a community and we do things together. You have also possibility to earn more by doing administrative tasks on the side of deliveries.")}

                </p>
              </div>
            </div>
          </div>
          {/* clmn div  */}
          <div className="ReiluDriver_video_div">
            <img src={ReiluVideo} alt="" className="ReiluDriver_video_img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReiluDriver;
