import React from "react";
import "../../css/Blogs/Blogs2Section.css";
import ProfiePic from "../../assets/Icons/ProfiePic.svg";

const Comments = () => {
  return (
    <>
      <div className="Comments_main">
        <div className="Comments_second">
          <p className="Comments_title">Comments</p>
          <div className="Comments_div">
            <div className="comments_profile">
              <img src={ProfiePic} alt="" className="Comments_profile_pic" />
            </div>
            <textarea
              name=""
              id=""
              cols="100"
              rows="6"
              placeholder="What are your thoughts on this blog?"
              className="Comments_textarea"
            ></textarea>
          </div>
          <div className="user_comment_btn_div">
            <button className="Comments_post_btn">Post a Comment</button>
          </div>
          {/* comments  */}
          <br />
          <hr />
          <div className="user_Comments_div">
            <div className="comments_profile">
              <img src={ProfiePic} alt="" className="Comments_profile_pic" />
            </div>
            <div className="Comments_user_message">
              <p className="Comments_user_p">
                Lorem Ipsum
                <span className="Comments_span">August 11, 2021</span>
              </p>
              <p className="Comments_user_Comments">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
          <div className="user_Comments_div">
            <div className="comments_profile">
              <img src={ProfiePic} alt="" className="Comments_profile_pic" />
            </div>
            <div className="Comments_user_message">
              <p className="Comments_user_p">
                Lorem Ipsum
                <span className="Comments_span">August 11, 2021</span>
              </p>
              <p className="Comments_user_Comments">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
          <div className="user_Comments_div">
            <div className="comments_profile">
              <img src={ProfiePic} alt="" className="Comments_profile_pic" />
            </div>
            <div className="Comments_user_message">
              <p className="Comments_user_p">
                Lorem Ipsum
                <span className="Comments_span">August 11, 2021</span>
              </p>
              <p className="Comments_user_Comments">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comments;
