/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../css/Home/Maps.css";
import React, { useState } from "react";
import Rootimg from "../../assets/Image/Root.png";
import Food from "../../assets/Image/Food.jpg";
import WatchNow from "../../assets/Image/WatchNow.svg";
import { useTranslation } from "react-i18next";
import Compress from '../../assets/Image/Compress.png'
// import { color } from "@mui/system";

// const ReadMore = ({ children }) => {
//   console.log('')
//   const text = children;
//   const [isReadMore, setIsReadMore] = useState(false);
//   const toggleReadMore = () => {
//     setIsReadMore(!isReadMore);
//   };
//   return (
//     <p className="text">
//       {isReadMore ? text.slice(0, 100) : text}
//       <span onClick={toggleReadMore} className="read-or-hide" style={{ color: "black" }}>
//         {isReadMore ? "...(read more)" : " (show less)"}
//       </span>
//     </p>
//   );
// };
const WhyReiluSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="WhyReilusMain pb-5 ">
        <div className=" WhyReilusMainBox  ">
          <div className="row Raduis ">
            <div className="col-lx-6 col-lg-6 col-md-6 col-ms-12 col-12 col_white_color1  ">
              <p className="WhyReiluText ">{t("Why Reilu")}</p>
              <p className="WhyReiluTextP">
                {/* <ReadMore> */}
                {t(
                  "Turku-based Reilu kuljetus delivers in a fair way! We make a fair contract with all our couriers and offer a fair commission to all our partner restaurants – without compromising quality and speed. First and foremost we care about our customers so you can concentrate on what is the most important for you. Reilu kuljetus delivers always more than you expected. Delivering with fairness and care ⏤ without compromising speed and quality")}.
                {/* </ReadMore> */}
              </p>
              <a href="https://youtube.com/shorts/opHVre_r2GM" target="_blank" style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
                <img src={WatchNow} alt="" className="watchme" />
                <p className="watchNow">{t("Watch Now")}</p>
              </a>
            </div>

            <div className="col-lx-6 col-lg-6 col-md-6 col-ms-12 col-12 col_white_color2 ">
              <img src={Food} alt="" className="foodImginWhyReilusection" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="tree_main">
        <div class="tree">
          <div className="com"><img style={{ height: "100%" }} src={Compress} alt="" /></div>
          <p className="text-white com_p">{t("Compensate your CO2 emissions – for the nature.")}</p>
        </div>
        <div className="root" >
          <img src={Rootimg} alt="" className="rootimg" />
        </div>
      </div> */}
    </>
  );
};

export default WhyReiluSection;
