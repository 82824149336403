import React from "react";
import "../../css/Driver/Fairness.css";
import "../../css/Restaurant/WhyReilu.css";
import Step1 from "../../assets/Icons/Step1.svg";
import Step2 from "../../assets/Icons/Step2.svg";
import Step3 from "../../assets/Icons/Step3.svg";
import Step4 from "../../assets/Icons/Step4.svg";
// import Blue from "../../assets/Image/bluecard.svg"
import { useTranslation } from "react-i18next";
import RestaurantForm from "../../FormPopup/RestaurantForm";
// import Restaurant from "../../assets/Image/Restaurant.svg"
// import Restaurant from "../../assets/Image/Restaurant app.svg"
// import Our_restaurant from "../../assets/Image/Our restaurant app keeps your orders in track. We will provide the equipment for you free of charge..svg"
// import AppImg from "../../assets/Image/AppImg.svg";
// import GoogleImg from "../../assets/Image/GoogleImg.svg";

const ReiluWorks = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const openAplyForm = () => {
    setOpen(true);
  };
  return (
    <>
      <RestaurantForm open={open} setOpen={setOpen} />

      <div className="Fairness_main">
        <div className="Fairness_socond">
          {/* heading 1  */}
          <div className="Fairness_heading_1">
            <p className="Fairness_heading_1_title">{t("How Reilu works?")}</p>
            <p className="Fairness_heading_1_p">
              {t("The Reilu delivery and pickup process is quick and easy.")}
            </p>
            <button className="Fairness_heading_1_button" onClick={openAplyForm}>{t("Apply Now")}</button>
          </div>

          <div className="Fairness_heading_2_main ">
            <div className="Fairness_heading_2_2">
              <div className="Fairness_heading_2_col ">
                <img
                  src={Step1}
                  alt=""
                  className="Fairness_heading_2_col_img"
                />
              </div>
              <div className="Fairness_heading_2_col2">
                <h1 className="Fairness_heading_2_title">
                  {t("Fill in your application")}
                </h1>
                <p className="Fairness_heading_2_p">
                  {t("Customers make orders on Reilu's app or website.")}
                </p>
              </div>
            </div>
          </div>
          {/* heading 3  */}
          <div className="Fairness_heading_3_main ">
            <div className="Fairness_heading_3_socond">
              <div className="Fairness_heading_3_colmn">
                <div className="Fairness_heading_3 ">
                  <div className="Fairness_heading_3_col ">
                    <img
                      src={Step3}
                      alt=""
                      className="Fairness_heading_3_col_img"
                    />
                  </div>
                  <div className="Fairness_heading_3_col2">
                    <h1 className="Fairness_heading_2_title">
                      {t("Get a Free App")}
                    </h1>
                    <p className="Fairness_heading_2_p">
                      {t("You will receive order from the customer on your phone and tablet")}
                    </p>
                  </div>
                </div>
              </div>
              {/* heading 4  */}
              <div className="Fairness_heading_3_colmn22">
                <div className="Fairness_heading_4 border">
                  <div className="Fairness_heading_2_col">
                    <img
                      src={Step2}
                      alt=""
                      className="Fairness_heading_3_col_img"
                    />
                  </div>
                  <div className="Fairness_heading_3_col2">
                    <h1 className="Fairness_heading_2_title">
                      {t("Sign a contract")}
                    </h1>
                    <p className="Fairness_heading_2_p">
                      {t("You will recieve a call and an online contract. Review and sign.")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* heading 4  */}
          <div className="Fairness_heading_2_main">
            <div className="Fairness_heading_5">
              <div className="Fairness_heading_3_col">
                <img
                  src={Step4}
                  alt=""
                  className="Fairness_heading_2_col_img"
                />
              </div>
              <div className="Fairness_heading_2_col2">
                <h1 className="Fairness_heading_2_title">
                  {t("Make your customers Happy")}
                </h1>
                <p className="Fairness_heading_2_p">
                  {t("Work with us to provide the same experience what they get at your restaurant")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 2 ind div  */}


      <div className="Fairness_main2">
        <div className="Fairness_socond2">
          <div className="Fairness_last_heading">
            <p className="Fairness_last_title">{t("How can we help?")}</p>
            <p className="Fairness_last_p">
              {t("Let us know how we could make our service better and more suitable for your needs (info@reilukuljetus.fi")})
            </p>
          </div>
        </div>
      </div>


      {/* <div className="SECOND_IMG2 "> */}
      {/* <img className="bluee" src={Blue} alt="" /> */}
      {/* <img className="Restaurant" src={Restaurant} alt="" />
        <img className="Our_restaurant" src={Our_restaurant} alt="" /> */}

      {/* <img className=" AppImg" src={AppImg} alt="" />
        <img className=" GoogleImg" src={GoogleImg} alt="" /> */}
      {/* </div> */}

    </>
  );
};

export default ReiluWorks;
