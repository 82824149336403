import React from "react";
import { NavLink } from "react-router-dom";

const Navigaiton = () => {
  return (
    <div>
      <li className="navigation_li">
        <NavLink to="/" className="navigation_a">
          Home
        </NavLink>
        <NavLink to="/about" className="navigation_a">
          About Us
        </NavLink>
        <NavLink to="/contact" className="navigation_a">
          Contact Us
        </NavLink>
        <NavLink to="/faq" className="navigation_a">
          FAQ
        </NavLink>
        {/* <NavLink to="/blogs" className="navigation_a">
          Blogs
        </NavLink>
        <NavLink to="/News" className="navigation_a">
          News
        </NavLink> */}
      </li>
    </div>
  );
};

export default Navigaiton;
