import React from "react";
import "../../css/Driver/HowCan.css";
import Blue from "../../assets/Image/bluecard.svg"
import Become from "../../assets/Image/Become a Reilu Driver and...svg"
import when from "../../assets/Image/When you are on the road, we are always there for you to help, advice and support when needed. Just message us on the app.svg"
import how from "../../assets/Image/How can we help_.svg"
import splash from "../../assets/Image/splash 1.svg"
import AppImg from "../../assets/Image/AppImg.svg";
import GoogleImg from "../../assets/Image/GoogleImg.svg";
import { useTranslation } from "react-i18next";


const HowCan = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="HowCan_main">
        <div className="HowCan_second">
          <div className="HowCan_heading">
            <h1 className="HowCan_h1">{t("How can we help?")}</h1>
            <p className="HowCan_p">
              {t("When you are on the road, we are always there for you to help, advice and support when needed. Just message us on the app!")}
            </p>
            <div className="HowCan_social_div">
              <img src={AppImg} alt="" className="HowCan_social_img" />
              <img src={GoogleImg} alt="" className="HowCan_social_img" />
            </div>
          </div>
        </div>
      </div>

      <div className="SECOND_IMG ">
        <img className="blue" src={Blue} alt="" />
        {/* <img className=" become" src={Become} alt="" /> */}
        <p className=" become">{t("Become a Reilu Driver and..")}</p>
        {/* <img className=" when" src={when} alt="" /> */}
        <p className=" when">{t("When you are on the road, we are always there for you to help, advice and support when needed. Just message us on the app!")}</p>
        {/* <img className=" how" src={how} alt="" /> */}
        <p className=" how">{t("How can we help?")}</p>
        <img className=" splash" src={splash} alt="" />
        <div className="res_social">
          <img className=" AppImg" src={AppImg} alt="" />
          <img className=" GoogleImg" src={GoogleImg} alt="" />
        </div>
      </div>
    </>
  );
};

export default HowCan;
