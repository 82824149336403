import React from "react";
import "../../css/Home/DriversCard.css";
import CardImg1 from "../../assets/Image/CardImg1.svg";
import CardImg2 from "../../assets/Image/CardImg2.svg";
import CardImg3 from "../../assets/Image/CardImg3.svg";
import Card from 'react-bootstrap/Card';
import { useTranslation } from "react-i18next";


const textData = [
  {
    img: CardImg1,
    title: "Drivers",
    text: "Become a part of a mission to change the way how delivery business works",
    link: "/Driver"
  },
  {
    img: CardImg2,
    title: "Restaurants",
    text: "Grow your business and reach more customers by partnering with us",
    link: "/Restaurant"
  },
  {
    img: CardImg3,
    title: "Jobs",
    text: "Start a career with Reilu and make a change.",
    
  },
];

const DriversCard = () => {
  const { i18n, t } = useTranslation();

  return (
    <> 
    <div  className="DriversCard_main1">
    <div className="DriversCard_second1" >

         
        {textData.map((item,index)=>{
          return (
            <>

     
            <div className="driver_cardChild ">
           <a href={item.link} className="driver_link">
           <Card className="cardres" style={{ backgroundColor:'#F6F6F6',border:"none"}}>
            <Card.Img className="cardimg" variant="top" src={item.img} style={{marginTop:"7%"}} />
            <Card.Body className="cardres1" style={{backgroundColor:"#F6F6F6" }}>
              <Card.Title className="title">{t(item.title)}</Card.Title>
              <Card.Text className="C_text" style={{color:"#A7A7A7"}}>{t(item.text)}</Card.Text>
            </Card.Body>
          </Card>
           </a>      
          </div>
          


          </>
          )
        
        })}



        </div>
      </div>

      
    </>
  );
};

export default DriversCard;














