import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Vector from "../../assets/Image/Vector.svg";
import { useTranslation } from "react-i18next";


const Support = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="Support_main">
        <div className="form_second_div">
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div className="Support_box ">
                  <img src={Vector} alt="" className="Support_img" />
                  <h2 className="support_h2">
                    {t("Reilu for New")} <br /> {t("Restaurants")}
                  </h2>
                  <p className="support_p">
                    {t("Are you a restaurant owner and interested in joining Reilu? let’s get in touch, we’d love to show how partnering with Reilu could help you grow your business.")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="Support_box ">
                  <img src={Vector} alt="" className="Support_img" />
                  <h2 className="support_h2">
                    {t("Support for")} <br /> {t("Restaurants")}
                  </h2>
                  <p className="support_p">
                    {t("You can access our Help Center for Restaurants directly from your Reilu’s restaurant app. Sign in to your Reilu restaurant app and click on “Help center” for information on how to update your menu, adjust your opening hours, and more.")}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Support;
