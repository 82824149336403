import React, { useState } from "react";
import "../../css/Home/HomeSection.css";
import F1 from "../../assets/Image/F1.svg";
import F2 from "../../assets/Image/momos.png";
import { useTranslation } from "react-i18next";

const ReadMore = ({ children }) => {

  const { t } = useTranslation();

  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 200) : text}
      <span onClick={toggleReadMore} className="read-or-hide" style={{ color: "black" }}>
        {isReadMore ? (t("(Read more)")) : (t("(Show less)"))}
      </span>
    </p>
  );
};

const Delivery = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="Delivery_main_din ">
        <div className="Delivery_center_div">
          {/* content  */}
          <div className="Delivery_content">
            <h2 className="Delivery_h2">{t("Fair delivery")}</h2>
            <p className="delivery_p">
              <ReadMore>
                {t("Reilu is a Turku-based startup that aims to provide a total customer-centric food delivery service. Fairness and quality service are paramount to us. Reilu is driven by passion to help the local food and beverage ecosystem thrive in the post-pandemic world. Our core values that define us include transparency, empowering small local businesses and providing fair working conditions to our delivery partners. We are a bunch of enthusiastic and friendly folk. We love hearing from you and believe in co-creating the service as per your wishes. Reilu offers fairer contracts to our delivery partners that make them a part of our company through a cooperative business model.")}
              </ReadMore>
            </p>
          </div>
          {/* images div  */}

          <div className=" d-flex justify-content-around w-100">
            <img src={F1} alt="" style={{ width: '45%' }} />
            <img src={F2} alt="" style={{ width: '45%' }} />
          </div>

          {/* <div className="container-fluid Delivery_image_div border d-flex justify-content-between">
            <div className="row jermaya">
              <div className=" Delivery_colmn_img ">
              <img src={F1} alt="" className="Delivery_image" />
            </div>
            <div className=" Delivery_colmn_img">
                <img src={F1} alt="" className="Delivery_image" />
            </div></div>
            
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Delivery;
