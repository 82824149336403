import React from "react";
import "../../css/News/NewsSection.css";
import InstaIcon from "../../assets/Icons/InstaIcon.svg";
import FbIcon from "../../assets/Icons/FbIcon.svg";
import TwitIcon from "../../assets/Icons/TwitIcon.svg";
import YtIcon from "../../assets/Icons/YtIcon.svg";

const NewsSection = () => {
  return (
    <>
      <div className="NewsSection_mainDiv">
        <div className="NewsSection_secondDiv">
          <div className="NewsSection_box">
            <div className="NewsSection_center_box">
              {/* heading  */}
              <div className="NewsSection_box_heading">
                <h2 className="NewsSection_box_h2">
                  Lorem ipsum dolor sit amet,{" "}
                </h2>
                <h2 className="NewsSection_box_p">
                  Reilu is a Turku-based startup that aims to provide a total
                  customer-centric food delivery service. Fairness and quality
                  service are paramount to us. Reilu is driven by passion to
                  helpReilu is a Turku
                </h2>
              </div>
              {/* Social account  */}
              <div className="NewsSection_social">
                <img
                  src={InstaIcon}
                  alt=""
                  className="NewsSection_social_img"
                />
                <img src={FbIcon} alt="" className="NewsSection_social_img" />
                <img src={TwitIcon} alt="" className="NewsSection_social_img" />
                <img src={YtIcon} alt="" className="NewsSection_social_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsSection;
