import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AboutBg from "../../assets/Image/ceo.png";

const AboutHome = () => {
  return (
    <>
      <div className="about_main">
        <div className="aboutHome_center_div">
          <Box sx={{ width: "100%" }}>
            <Grid
              className="about_colmn"
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <h1 className="aboutHome_h1">
                Tilaa ruokaa hyvällä
omallatunnolla!
                </h1>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* <p className="aboutHome_p">
                Reilua välittämistä! Nauti ruoastasi hyvällä omallatunnolla.
                </p> */}
              </Grid>
            </Grid>
          </Box>
          <div className="aboutHome_bg_div">
            <img src={AboutBg} alt="" className="aboutHome_image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHome;
