import React from "react";
import Dialog from "@mui/material/Dialog";
import "../FormPopup/Registration.css";
import { NavLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";


const Registration = ({ open, setOpen }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        className="dialog_box"
      >
        <div className="Registration_main">
          <CloseIcon
            onClick={handleClose}
            style={{
              marginLeft: "auto",
              display: "block",
              width: "2rem",
              height: "2rem",
              cursor: "pointer",
            }}
          />
          <p className="Registration_title">{t("Driver Registration Form")}</p>
          <div className="Registration_second">
            <div className="Registration_colmn">
              {/* input div  */}
              <div className="Registration_inputs_div">
                <label htmlFor="" className="Registration_label">
                  {t("First name")}
                </label>
                <input
                  type="text"
                  className="Registration_input"
                  placeholder={t("First name")}
                />
              </div>
              <div className="Registration_inputs_div">
                <label htmlFor="" className="Registration_label">
                  {t("Mobile number")}
                </label>
                <input
                  type="text"
                  className="Registration_input"
                  placeholder={t("Number")}
                />
              </div>
              <div className="Registration_inputs_div">
                <label for="cars" className="Registration_label">
                  {t("Your Vehicle")}
                </label>

                <select id="cars" name="cars" className="Registration_input">
                  <option value="volvo">Vehicle</option>
                  <option value="saab">Vehicle</option>
                  <option value="mercedes">Vehicle</option>
                  <option value="audi">Vehicle</option>
                </select>
              </div>
            </div>

            {/* div inputs 2  */}
            <div className="Registration_colmn">
              <div className="Registration_inputs_div">
                <label htmlFor="" className="Registration_label">
                  {t("Last Name")}
                </label>
                <input
                  type="text"
                  className="Registration_input"
                  placeholder={t("Last Name")}
                />
              </div>
              <div className="Registration_inputs_div">
                <label htmlFor="" className="Registration_label">
                  {t("E-Email")}
                </label>
                <input
                  type="text"
                  className="Registration_input"
                  placeholder={t("Email")}
                />
              </div>
              <div className="Registration_inputs_div">
                <label for="cars" className="Registration_label">
                  {t("Language")}
                </label>

                <select id="cars" name="cars" className="Registration_input">
                  <option value="volvo">English</option>
                  <option value="saab">Hindi</option>
                  <option value="mercedes">Suomi</option>
                  <option value="audi">Franch</option>
                </select>
              </div>
            </div>
          </div>
          {/* check box div */}
          <div className="check_box_div">
            <div className="Registration_inputs_div">
              <legend className="Registration_label">
                {t("Do you have a valid work permit?")}
              </legend>
              {/* radio 1  */}
              <span className="Registration_gnder">
                <input
                  className="gnder_inpt"
                  type="radio"
                  name="gender"
                  value=""
                  checked
                />
                <label className="gnder_label" for="Yes">
                  {t("Yes")}
                </label>
              </span>
              <span className="Registration_gnder">
                <input
                  className="gnder_inpt"
                  type="radio"
                  name="gender"
                  value=""
                />
                <label className="gnder_label" for="No">
                  {t("No")}
                </label>
              </span>
            </div>
            {/* radio 2  */}
            <div className="Registration_inputs_div">
              <legend className="Registration_label">
                {t("Do you have a driving license?")}
              </legend>
              <span className="Registration_gnder">
                <input
                  className="gnder_inpt"
                  type="radio"
                  name="gender"
                  value=""
                  checked
                />
                <label className="gnder_label" for="Yes">
                  {t("Yes")}
                </label>
              </span>
              <span className="Registration_gnder">
                <input
                  className="gnder_inpt"
                  type="radio"
                  name="gender"
                  value=""
                />
                <label className="gnder_label" for="No">
                  {t("No")}
                </label>
              </span>
            </div>
            <div className="Registration_inputs_div" style={{ display: "flex" }}>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
                className="check_terms"
              />
              <label for="vehicle1" className="Registration_label2">
                {t("I've read and agree with the User")}&nbsp;
                <NavLink to="#" className="Terms">
                  {t("Terms and condition")}
                </NavLink>
              </label>
            </div>
            <button className="Registration_btn">{t("Apply Now")}</button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Registration;
