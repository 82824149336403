import React from "react";
import Man1 from "../../assets/Image/Man1.svg";
import Man2 from "../../assets/Image/Man2.svg";
import { useTranslation } from "react-i18next";


const OurBrilliant = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="OurBrilliant_main">
        <div className="OurBrilliant_second">
          {/* heading  */}
          <div className="OurBrilliant_heading">
            <h2 className="ourBrilliant_h2">{t("Our Brilliant team is here to help")}</h2>
            <p className="ourBrilliant_p">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
            </p>
          </div>
          <div className="ourCard">
            <div className="ourCard_col">
              <div className="ourCard_col_image_div">
                <img src={Man1} alt="" className="ourCard_man_img" />
                <div className="ourCard_heading">
                  <p className="ourCard_title">Rufus Vinod</p>
                  <p className="ourCard_p">{t("Founder")}</p>
                </div>
              </div>
            </div>
            <div className="ourCard_col2">
              <div className="ourCard_content">
                <p className="ourCard_content_p">
                  {t("Rufus Vinod is a Ph.D student and an entrepreneur. During Ph.D at university of Turku he started working as a courier partner in a delivery company. He felt the need of a delivery system that would provide an provide better wording conditions for the couriers and provide customers with a local and provides the customer a local platform.Reilu is a Finnish company and TURKU will always be our home.")}
                </p>
              </div>
            </div>
          </div>
          <div className="ourCard">
            <div className="ourCard_col">
              <div className="ourCard_col_image_div">
                <img src={Man2} alt="" className="ourCard_man_img" />
                <div className="ourCard_heading">
                  <p className="ourCard_title">Lauri Koittola</p>
                  <p className="ourCard_p">{t("Founder")}</p>
                </div>
              </div>
            </div>
            <div className="ourCard_col2">
              <div className="ourCard_content">
                <p className="ourCard_content_p">
                  {t("Lauri Koittola is a serial entrepreneur and a team coach, who ended up as a business partner through a common friend. In the beginning Lauri’s main responsibility was connections to restaurants and media. After launching the business the emphasis shifted to coaching the team with self-managing methods. Lauri is also responsible for the matters regarding cooperative business model.")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5"></div>
    </>
  );
};

export default OurBrilliant;
