import React from "react";
import "../../css/News/Trending.css";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const Trending = () => {
  return (
    <>
      <div className="Trending_maindiv">
        <div className="Trending_secondDiv">
          <p className="Trending_title">Trending</p>
          <div className="Trending_box">
            <div className="Trending_colmns">
              {/* Trending  */}
              <CardContent className="Trending_headings">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="card_heading"
                >
                  <div>
                    <p className="card_title" style={{ color: "#fff" }}>
                      Lorem ipsum
                    </p>
                    <em className="card_title22">
                      By Bilal Ahmed August 11, 2021
                    </em>
                  </div>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p className="card_title_p2">
                    Reilu is a Turku-based startup that aims to provReilu is a
                    Turku-based startup that aims to prov
                  </p>
                </Typography>
              </CardContent>
              {/* Trending  */}
            </div>
            <div className="Trending_colmns2">
              <div className="Trending_row">
                <div className="Trending_row_colmn">
                  {/* Trending  */}
                  <CardContent className="Trending_headings">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="card_heading"
                    >
                      <div>
                        <p className="card_title" style={{ color: "#fff" }}>
                          Lorem ipsum
                        </p>
                        <em className="card_title22">
                          By Bilal Ahmed August 11, 2021
                        </em>
                      </div>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <p className="card_title_p2">
                        Reilu is a Turku-based startup that aims to provReilu is
                        a Turku-based startup that aims to prov
                      </p>
                    </Typography>
                  </CardContent>
                  {/* Trending  */}
                </div>
              </div>
              <div className="Trending_row2">
                <div className="Trending_row_colmn2">
                  {/* Trending  */}
                  <CardContent className="Trending_headings">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="card_heading"
                    >
                      <div>
                        <p className="card_title" style={{ color: "#fff" }}>
                          Lorem ipsum
                        </p>
                        <em className="card_title22">
                          By Bilal Ahmed August 11, 2021
                        </em>
                      </div>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <p className="card_title_p2">
                        Reilu is a Turku-based startup that aims to provReilu is
                        a Turku-based startup that aims to prov
                      </p>
                    </Typography>
                  </CardContent>
                  {/* Trending  */}
                </div>
                <div className="Trending_row_colmn3">
                  {/* Trending  */}
                  <CardContent className="Trending_headings">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="card_heading"
                    >
                      <div>
                        <p className="card_title" style={{ color: "#fff" }}>
                          Lorem ipsum
                        </p>
                        <em className="card_title22">
                          By Bilal Ahmed August 11, 2021
                        </em>
                      </div>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <p className="card_title_p2">
                        Reilu is a Turku-based startup that aims to provReilu is
                        a Turku-based startup that aims to prov
                      </p>
                    </Typography>
                  </CardContent>
                  {/* Trending  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trending;
