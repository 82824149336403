/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import AboutBg from "../../assets/Image/aboutus2.png";
import Food from "../../assets/Image/Food.jpg";



const WhatWeAre = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="aboutHome_center_div">
        <div className="whatWeAre">
          <Box sx={{ width: "100%" }}>
            <Grid
              className="about_colmn"
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={5}>
                <div className="what_we">
                  <h1 className="what_we_h1">{t("What we are?")}</h1>
                  <div className="what_we_box">
                  <img src={AboutBg} alt="" className="aboutHome_image" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={7}>
                <div className="what_we_heading">
                  <h2 className="what_we_h2">
                    {t("Reilu kuljetus is Rufus’ brainchild. Rufus is a cancer researcher and a part-time courier who brings joy to Turku citizens. While working as a courier, he realized how little the couriers were able to influence their working conditions.It’s about time things changed. He gathered a team of enthusiasts to create a fair food delivery service which helps the small local businesses to sustain while creating better working conditions for the delivery partners and restaurants.")}
                  </h2>
                  <h2 className="" style={{fontSize:'20px',lineHeight:'2rem'}}>
                    {t("Lauri was invited first to join and become a business partner in the company. He’s a natural people’s person making him an invaluable asset to the company who networks with the local restaurants and the media.")}
                  </h2>
                  
                  <h2 className="" style={{fontSize:'20px',lineHeight:'2rem'}}>
                    {t("We are expanding. We now have service designers who are working round the clock making the service as friendly as possible. We look at our customers not just as someone who pay us for our service, but also someone who helps us in developing our services further. With Reilu kuljetus, you are not just customers, you are co-creators.")}
                  </h2>
                  {/* <p className="what_we_p">
                    {t("Reilu is run by a team of professional enthusiasts who aim at making the food delivery service more ethical and sustainable. Our delivery partners are actually decision makers in Reilu’s model. Together with the best local restaurant partners, we deliver in a more ethical and responsible manner towards the environment. Above all, Reilu takes the safety of your data seriously and does not charge the customers any extra to enjoy the premium service. We are a fully approachable team and would always love to hear from you, find more about us [here.]")}
                  </p> */}
                  {/* <em className="what_we_em">
                    <a href="">www.reilukuljetus.fi/contact-us</a>
                  </em> */}
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
        {/* 2in div  */}
        <div className="whatWeAre">
          <Box sx={{ width: "100%" }}>
            <Grid
              className="about_colmn"
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={7}>
                <div className="what_we_heading">
                  <h2 className="what_we_h2">
                    {t("Reilu kuljetus is a Turku-based startup that brings the best of the local restaurants to your doorstep in a fair and premium manner. Behind the scenes,")}
                  </h2>
                  <p className="what_we_p">
                    {t("Reilu is run by a team of professional enthusiasts who aim at making the food delivery service more ethical and sustainable. Our delivery partners are actually decision makers in Reilu’s model. Together with the best local restaurant partners, we deliver in a more ethical and responsible manner towards the environment. Above all, Reilu takes the safety of your data seriously and does not charge the customers any extra to enjoy the premium service. We are a fully approachable team and would always love to hear from you, find more about us")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <div className="what_we2">
                  <h1 className="what_we_h1">{t("What we are?")}</h1>
                  <div className="what_we_box">
                  <img src={Food} alt="" className="aboutHome_image" />

                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
        {/* 2in div  */}
        <div className="what_we_hr"></div>
      </div>
    </>
  );
};

export default WhatWeAre;
