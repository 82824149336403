/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
import DriveImg from "../../assets/Image/Uncle.png";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Uncle from "../../assets/Image/Uncle.png";
import Switch from "@mui/material/Switch";
import "../../css/Home/HomeSection.css";
import Apple from "../../assets/Image/APP.png";
import Play from "../../assets/Image/Play.png"
import "../../utils/i18n";
import { useTranslation } from "react-i18next";


const HomeSection = ({ check, setcheck }) => {
  const { t } = useTranslation();


  return (
    <>
      <div className="Home_Section">
        <div className="drive_bike1">
          <img src={DriveImg} alt="" className="bike_img1 " />
        </div>
        <div className="backgroundImage_div">
          <div className="nav_menu_div">
            <div className="language_switch">
              <span className="language">English</span>
              <FormControlLabel onChange={(e) => setcheck(e.target.checked)}
                control={<Switch defaultChecked color="warning" />}
              />
            </div>
            <span className="language" style={{ color: "black" }}>
              Suomi
            </span>

          </div>
          <h1 className="Feeling_hungry">{t("ORDER FOOD WITH A GOOD CONSCIENCE!")}</h1>
          <p className="home_p">
            "{t("New food delivery service in Turku")}"
          </p>
          <div className="collection">
            <div className="apple">
              <a href="http://onelink.to/reilukuljetus">
                <img src={Apple} alt="" />
              </a>
            </div>
            <div className="playstore">
              <a href="http://onelink.to/reilukuljetus">
                <img src={Play} alt="" />
              </a>
            </div>
            <a href="http://onelink.to/reilukuljetus">
              <div className="CallAction_btn">{t("Available Now")}</div>
            </a>

          </div>
        </div>
        <div className="drive_bike">
          <img src={DriveImg} alt="" className="bike_img" />
        </div>
      </div>
    </>
  );
};
export default HomeSection;
