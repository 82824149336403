import React from "react";
import "../../css/Restaurant/WhyReilu.css";
import WolletIcon from "../../assets/Icons/WolletIcon.svg";
import LocationIcon from "../../assets/Icons/LocationIcon.svg";
import GroupIcon from "../../assets/Icons/GroupIcon.svg";
import WhyBg from "../../assets/Image/WhyBg.svg";
import { useTranslation } from "react-i18next";


const WhyReilu = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="WhyReilu_main">
        <h2 className="WhyReilu_title">{t("Why Reilu?")}</h2>
        <div className="WhyReilu_second pt-5">
          <div className="WhyReilu_col WhyReilu_col_2 ">
            <div className="ReiluDriver_col" style={{ width: "100%" }}>

              <div className="ReiluDriver_col_content">
                <img src={WolletIcon} alt="" className="ReiluDriver_col_img " />
                <h2 className="ReiluDriver_col_h2">{t("Fair Commission")}</h2>
                <p className="ReiluDriver_col_p_2">
                  {t("Make sure you get the best possible commission to boost your profits from food deliveries.")}
                </p>
              </div>
            </div>
            <div className="ReiluDriver_col" style={{ width: "100%" }}>
              <div className="ReiluDriver_col_content">
                <img
                  src={LocationIcon}
                  alt=""
                  className="ReiluDriver_col_img"
                />
                <h2 className="ReiluDriver_col_h2">{t("Locality")}</h2>
                <p className="ReiluDriver_col_p_2">
                  {t("As a local business we create close relationships with our partners to offer best possible customer service.")}
                </p>
              </div>
            </div>
            <div className="ReiluDriver_col" style={{ width: "100%" }}>
              <div className="ReiluDriver_col_content">
                <img src={GroupIcon} alt="" className="ReiluDriver_col_img" />
                <h2 className="ReiluDriver_col_h2">
                  {t("Interaction with your customers:")}
                </h2>
                <p className="ReiluDriver_col_p_2">
                  {t("We make sure you can communicate with your customers, reply customer feedbacks and answer possible complains.")}
                </p>
              </div>
            </div>
          </div>
          <div className="WhyReilu_col2 WhyReilu_col_2">
            <div className="WhyReilu_image_div">
              <img src={WhyBg} alt="" className="WhyReilu_img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyReilu;
