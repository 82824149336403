/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MuiPhoneNumber from "material-ui-phone-number";
// import Contact_map from "../../assets/Image/Contact_map.jpg";
import { useTranslation } from "react-i18next";


const Form = () => {
  const { t } = useTranslation();

  function handleOnChange(value) {
    this.setState({
      phone: value,
    });
  }
  return (
    <>
      <div className="form_main">
        <div className="form_second_div">
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div className="contact_us">
                  {/* for headings  */}
                  <div className="contact_haa">
                    <h1 className="contact_h1">{t("Contact us")}</h1>
                    <p className="contact_p">
                      {t("Do you want to contact us? Great, you can do it using the form below. We are happy to hear thoughts, development proposals and general feedback! Fair transport is for you, so your opinion is extremely important to us.")}
                    </p>
                  </div>
                  <div className="contact_input">
                    <label className="contect_label" htmlFor="">
                      {t("Name")}
                    </label>
                    <input
                      type="text"
                      className="inputfields"
                      placeholder={t("Name")}
                    />
                  </div>
                  <div className="contact_input">
                    <label className="contect_label" htmlFor="">
                      {t("Mobile number")}
                    </label>

                    <div className="country_feilds">
                      <MuiPhoneNumber
                        defaultCountry={"fi"}
                        onChange={handleOnChange}
                        className="coutry_dropdown"
                      />
                      <input
                        type="phone"
                        className="inputfields_number"
                        placeholder={t("Mobile number")}
                      />
                    </div>
                  </div>
                  <div className="contact_input">
                    <label className="contect_label" htmlFor="">
                      {t("Email address")}
                    </label>
                    <input
                      type="email"
                      className="inputfields"
                      placeholder={t("Email address")}
                    />
                  </div>
                  <div className="contact_input">
                    <label className="contect_label" htmlFor="">
                      {t("Subject")}
                    </label>
                    <input
                      type="text"
                      className="inputfields"
                      placeholder={t("Subject")}
                    />
                  </div>
                  <div className="contact_input">
                    <label className="contect_label" htmlFor="">
                      {t("Comments")}
                    </label>
                    <textarea
                      placeholder={t("Comments")}
                      className="contactTextarea"
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <button className="contact_submit">{t("Submit")}</button>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="form_colmn_2">
                  <iframe
                    className="contact_map"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Tykist%C3%B6katu%204B,%2020520%20Turku&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                  <a href="https://123movies-to.org"></a>
                  <br />
                  <a href="https://www.embedgooglemap.net"></a>
                  <p className="contact_map_p">
                    Address: Tykistökatu 4B, 20520 Turku
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Form;
