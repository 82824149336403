import React from "react";
import "../../css/Blogs/Blogs2Section.css";
import Bloge2Img from "../../assets/Image/Bloge2Img.svg";

const Blogs2Section = () => {
  return (
    <>
      <div className="Blogs2Section_main">
        <div className="Blogs2Section_second">
          {/* heading  */}
          <div className="Blogs2Section_heading">
            <p className="Blogs2Section_title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <p className="Blogs2Section_p">
              Reilu is a Turku-based startup that aims to provide a total
              customer-centric food delivery service. Fairness and quality
              service are paramount to us. Reilu is driven by passion to
              helpReilu is a Turku
            </p>
          </div>
          {/* image div  */}
          <div className="Blogs2Section_image">
            <img src={Bloge2Img} alt="" className="Blogs2Section_img" />
            <em className="Blogs2Section_by_name">
              By Bilal Ahmed August 11, 2021
            </em>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs2Section;
