import React, { useEffect, useState } from "react";
import Header from "../components/Home/Header";
import DriverSection from "../components/Driver/DriverSection";
import ReiluDriver from "../components/Driver/ReiluDriver";
import Fairness from "../components/Driver/Fairness";
import HowCan from "../components/Driver/HowCan";
import Footer2 from "../components/Home/Footer2";
import { useTranslation } from "react-i18next";


const Driver = () => {
  React.useState(false);

  const { i18n, } = useTranslation();
  const [check, setcheck] = useState(true)
  useEffect(() => {
    if (check === false) {
      i18n.changeLanguage("en")
    }
    else if (check === true) {
      i18n.changeLanguage("fr")
    }

  }, [check])

  const scrollToTop = () => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
  };
  React.useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <Header check={check} setcheck={setcheck}/>
      <DriverSection />
      <ReiluDriver />
      <Fairness />
      <HowCan />
      <Footer2 check={check} setcheck={setcheck} />

    </div>
  );
};

export default Driver;
