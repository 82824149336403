import React from "react";
import "../../css/Blogs/Blogs2Section.css";
import InstaIcon from "../../assets/Icons/InstaIcon.svg";
import FbIcon from "../../assets/Icons/FbIcon.svg";
import TwitIcon from "../../assets/Icons/TwitIcon.svg";
import YtIcon from "../../assets/Icons/YtIcon.svg";
import ShareIcon from "../../assets/Icons/ShareIcon.svg";
import ArticleHeart from "../../assets/Image/ArticleHeart.svg";
import RelatedImg from "../../assets/Icons/RelatedImg.svg";
// card
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import BlogsImg from "../../assets/Image/BlogsImg.svg";
import CardHeart from "../../assets/Icons/CardHeart.svg";

const Article = () => {
  return (
    <>
      <div className="Article_main">
        <div className="Article_second">
          <div className="Article_heading_colmns">
            <dv className="Article_colmn1">
              {/* heading 1  */}
              <p className="Article_colmn1_p">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.Mauris
                id mauris ut sed varius egestas pellentesque a.Sed accumsan
                dictum eu amet neque id. Quisque Lorem ipsum dolor sit amet,
                consectetur adipiscing elit.Mauris id mauris ut sed varius
                egestas pellentesque a.Sed accumsan dictum eu
              </p>
            </dv>
            <dv className="Article_colmn2">
              <div className="Article_colmn2_social_div">
                <img src={InstaIcon} alt="" className="main_footer_social3" />
                <img src={FbIcon} alt="" className="main_footer_social3" />
                <img src={TwitIcon} alt="" className="main_footer_social3" />
                <img src={YtIcon} alt="" className="main_footer_social4" />
              </div>
              <div className="Article_share_like">
                <img src={ArticleHeart} alt="" className="ArticleHeart" />
                <img src={ShareIcon} alt="" className="ShareIcon" />
              </div>
            </dv>
          </div>
          {/* related main div  */}
          <div className="Related_main_div">
            <div className="Related_vlogs_colmn1">
              <p className="Related_p1">
                Reilu is a Turku-based startup that aims to provide a total
                customer-centric food delivery service. Fairness and quality
                service are paramount to us. Reilu is driven by passion to
                helpReilu is a Turku-based startup that aims to provide a total
                customer-centric food delivery service. Fairness and quality
                service are paramount to us. Reilu is driven by passion to
                helpRe
              </p>
              {/* Related_vlogs_heading  */}
              <div className="Related_vlogs_heading">
                <p className="Related_vlogs_title">
                  Lorem ipsum dolor sit amet,
                </p>
                <p className="Related_vlogs_heading_p">
                  Reilu is a Turku-based startup that aims to provide a total
                  customer-centric food delivery service. Fairness and quality
                  service are paramount to us. Reilu is driven by passion to
                  helpReilu is a Turku-based startup that aims to provide a
                  total customer-centric food delivery service. Fairness and
                  quality service are paramount to us. Reilu is driven by
                  passion to helpReReilu is a Turku-based startup that aims to
                  provide a total customer-centric food delivery service.
                  Fairness and quality service are paramount to us
                </p>
              </div>
              <div className="Related_vlogs_images_div">
                <img
                  src={RelatedImg}
                  alt=""
                  className="Related_vlogs_img related2"
                />
                <img src={RelatedImg} alt="" className="Related_vlogs_img" />
                <img src={RelatedImg} alt="" className="Related_vlogs_img" />
                <img src={RelatedImg} alt="" className="Related_vlogs_img" />
              </div>
              <p className="Related_last_p">
                Reilu is a Turku-based startup that aims to provide a total
                customer-centric food delivery service. Fairness and quality
                service are paramount to us. Reilu is driven by passion to
                helpReilu is aReilu is a Turku-based startup that aims to
                provide a total customer-centric food delivery service. Fairness
                and quality service are paramount to us. Reilu is driven by
                passion to helpReilu is aReilu is a Turku-based startup that
                aims to provide a total customer-centric food delivery service.
                Fairness and quality service are paramount to us. Reilu is
                driven by passion to helpReilu is a
              </p>
              <div className="tag_div">
                <p className="tag_title">Tags</p>
                <p className="Tages_type">
                  #Lorem #Lorem #Lorem #Lorem #Lorem #Lorem #Lorem #Lorem #Lorem
                  #Lorem #Lorem #Lorem #Lorem #Lorem
                </p>
              </div>
            </div>
            <div className="Related_vlogs_colmn2">
              <p className="Related_card_title">Related Vlogs</p>
              <Grid item sm={4} xs={12}>
                {/* card  */}

                <Card>
                  <CardMedia
                    component="img"
                    image={BlogsImg}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="card_heading"
                    >
                      <div>
                        <p className="card_title">Lorem ipsum</p>
                        <p className="card_title2">
                          By Bilal Ahmed August 11, 2021
                        </p>
                      </div>
                      <img src={CardHeart} alt="" className="card_heart" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <p className="card_title_p">
                        Reilu is a Turku-based startup that aims to provReilu is
                        a Turku-based startup that aims to prov
                      </p>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              {/* card 2  */}
              <Grid item sm={4} xs={12} mt={3}>
                {/* card  */}

                <Card>
                  <CardMedia
                    component="img"
                    image={BlogsImg}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="card_heading"
                    >
                      <div>
                        <p className="card_title">Lorem ipsum</p>
                        <p className="card_title2">
                          By Bilal Ahmed August 11, 2021
                        </p>
                      </div>
                      <img src={CardHeart} alt="" className="card_heart" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <p className="card_title_p">
                        Reilu is a Turku-based startup that aims to provReilu is
                        a Turku-based startup that aims to prov
                      </p>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </div>
          </div>
          {/* related main div  */}
        </div>
      </div>
    </>
  );
};

export default Article;
