/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import FooterLogo from "../../assets/Image/FooterLogo.svg";
import PhoneIcon from "../../assets/Icons/PhoneIcon.svg";
import EmailIcon from "../../assets/Icons/EmailIcon.svg";
import LocatIcon from "../../assets/Icons/LocatIcon.svg";
import InstaIcon from "../../assets/Icons/InstaIcon.svg";
import FbIcon from "../../assets/Icons/FbIcon.svg";
import TwitIcon from "../../assets/Icons/TwitIcon.svg";
import Linkdin from "../../assets/Image/linkdin.png";
import YtIcon from "../../assets/Icons/YtIcon.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Footer2 = ({ check, setcheck }) => {
  const { i18n, t } = useTranslation();

  // const [check, setcheck] = useState(true)
  // useEffect(() => {
  //   if (check === false) {
  //     i18n.changeLanguage("en")
  //   }
  //   else if (check === true) {
  //     i18n.changeLanguage("fr")
  //   }

  // }, [check])

  return (
    <>
      <div style={{ width: "90%", margin: "auto", display: "flex" }}>
        <NavLink exact to="/">
          <img src={FooterLogo} alt="" className="footer_logo" />
        </NavLink>
      </div>
      <div className="main_footer">
        <div className="main_footer_centerDiv">
          {/* 1 colmn  */}
          <div className="main_footer_colms">
            <div className="main_footer_headings">
              <h2 className="main_footer_h2">{t("Get to know us")}</h2>
              <NavLink exact to="/about" className="main_footer_a">
                {t("About us")}
              </NavLink>
              <NavLink exact to="/contact" className="main_footer_a">
                {t("Contact us")}
              </NavLink>
              {/* <NavLink exact to="/blogs2" className="main_footer_a">
                {t("FAQ")}
              </NavLink> */}
              {/* <NavLink exact to="/blogs" className="main_footer_a">
                {t("Blogs")}
              </NavLink>
              <NavLink exact to="/News" className="main_footer_a">
                {t("News")}
              </NavLink> */}
            </div>
          </div>
          {/* 2 colmn  */}
          <div className="main_footer_colms">
            <div className="main_footer_headings">
              <h2 className="main_footer_h2">{t("Contact info")}</h2>
              <div exact to="#" className="main_footer_a">
                <img src={PhoneIcon} alt="" className="main_footer_icons" />
                <a className="main_footer_a" href="tel:+358468971839">
                  +358 46 8971839
                </a>
              </div>
              <div exact to="#" className="main_footer_a">
                <img src={EmailIcon} alt="" className="main_footer_icons" />

                <a
                  className="main_footer_a"
                  href="mailto:info@reilukuljetus.fi"
                >
                  info@reilukuljetus.fi
                </a>
              </div>
              <div exact to="#" className="main_footer_a">
                <img src={LocatIcon} alt="" className="main_footer_icons" />
                Turku {t("Finland")}
              </div>
            </div>
          </div>
          {/* 3 colmn  */}
          <div className="main_footer_colms">
            <div className="main_footer_headings">
              <h2 className="main_footer_h2">{t("Partners")}</h2>
              <NavLink to="/Driver" className="main_footer_a">
                {t("Driver")}
              </NavLink>
              <NavLink to="/Restaurant" className="main_footer_a">
                {t("Restaurant")}
              </NavLink>
            </div>
          </div>
          {/* 4 colmn  */}
          <div className="main_footer_colms">
            <div className="main_footer_headings">
              <h2 className="main_footer_h2">{t("News Letter")}</h2>
              <p className="main_footer_pera">
                {t(
                  "Sign up for our newsletter and be the first to know about our special offers!"
                )}
              </p>
              <div className="main_footer_input_div">
                <input
                  type="text"
                  className="main_footer_input"
                  placeholder={t("Email")}
                />
                <button className="main_footer_btn">{t("Subscribe")}</button>
              </div>
              <div className="main_footer_social_div">
                <a
                  target="_blank"
                  href="https://www.instagram.com/reilukuljetus/"
                >
                  <img src={InstaIcon} alt="" className="main_footer_social" />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/reilukuljetus"
                >
                  <img src={FbIcon} alt="" className="main_footer_social" />
                </a>
                <a target="_blank" href="www.twitter.com/reilukuljetus">
                  <img src={TwitIcon} alt="" className="main_footer_social" />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCSB0oC7JWRe8sDDSldZfpfw"
                >
                  <img src={YtIcon} alt="" className="main_footer_social" />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/reilukuljetus/"
                >
                  <img src={Linkdin} alt="" className="main_footer_social" />
                </a>
              </div>
              <div className="language_switch">
                <span className="language">{t("English")}</span>
                <FormControlLabel onChange={(e) => setcheck(e.target.checked)}
                  control={<Switch defaultChecked color="warning" />}
                  label={t("Finnish")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="down_footer">
        <p className="down_footer_p">{t("Delivigo oy 2023 ALL Right reserved")}</p>
        <div className="down_footer_heading">
          <NavLink to="/privacy" className="down_footer_p2 color-black">
            {t("Privacy Policy")}
          </NavLink>
          <NavLink to="/terms" className="down_footer_p2 color-black">
            {t("Terms & Conditions")}
          </NavLink>
        </div>

      </div>

    </>
  );
};

export default Footer2;
