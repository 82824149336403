import React from "react";
import "../../css/Home/DriversCard.css";
// import CardImg1 from "../../assets/Image/CardImg1.svg";
// import CardImg2 from "../../assets/Image/CardImg2.svg";
// import CardImg3 from "../../assets/Image/CardImg3.svg";
import CardImg1 from "../../assets/Image/bikeIcon.png";
import CardImg2 from "../../assets/Image/centerIcon.png";
import CardImg3 from "../../assets/Image/lastIcon.png"

import { useTranslation } from "react-i18next";
const textData = [
  {
    img: CardImg1,
    title: "Kuljettajat",
    text: "Reilu kuljetuksen kuljettajat ovat osa osuuskuntaa, jossa he voivat aidosti yrittäjinä vaikuttaa päätöksentekoon",
  },
  {
    img: CardImg2,
    title: "Ravintolat",
    text: "Reilu pyrkii tekemään mahdo- llisimman oikeudenmukaisetsopimukset kumppaneidenkanssa.",
  },
  {
    img: CardImg3,
    title: "Ympäristö",
    text: "Meille on tärkeää, että voit halutessasi kompensoida ruuan valmistuksen ja kuljetuksen CO2-päästöt",
  },
];

const HeaderCard = () => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <div className="DriversCard_main ">
        <div className="DriversCard_second ">
       
              <> 
                <div className="DriversCard_card border ">
                  <div className="DriversCard_card_content">
                    <div className="DriversCircle">
                      <img src={CardImg1}/>
                      </div>
                    <p className="headerCard_card_title">{t("Drivers")}</p>
                    <p className="headerCard_card_p">{t("Reilu drivers are members of a cooperative which enables them to have a say in operations.")}</p>
                  </div>
                </div>

                <div className="DriversCard_card border ">
                  <div className="DriversCard_card_content">
                    <div className="DriversCircle">
                      <img src={CardImg2}/>
                      </div>
                    <p className="headerCard_card_title">{t("Restaurants")}</p>
                    <p className="headerCard_card_p">{t("Reilu makes fair and just contracts with all partners.")}</p>
                  </div>
                </div>

                <div className="DriversCard_card border ">
                  <div className="DriversCard_card_content">
                    <div className="DriversCircle">
                      <img src={CardImg3}/>
                      </div>
                    <p className="headerCard_card_title">{t("Environment")}</p>
                    <p className="headerCard_card_p">{t("It’s important for us that you can compensate the co2 emissions of your food production and delivery.")}</p>
                  </div>
                </div>
              </>
          
        </div>
      </div>
    </>
  );
};

export default HeaderCard;
