// Init
import React, {useEffect} from "react";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from 'react-ga';
import "./utils/i18n";
import { useTranslation } from "react-i18next";
// Files
import Index from "./routes/index";
import "./css/index.css";
const TRACKING_ID = "UA-217342528-1" // OUT Tracking_id
ReactGA.initialize(TRACKING_ID);
// Component
function App() {
  const { i18n, t } = useTranslation();
    useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="App">
      {/* <button className="flag01" onClick={() => i18n.changeLanguage("en")}>
        <img
          alt="English"
          src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png"
          style={{ heigth: "50px", width: "50px" }}
          className="flags-appbar langFlags"
        />
      </button>
      <button className="flag02" onClick={() => i18n.changeLanguage("fr")}>
        <img
          alt="Russia"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/1200px-Flag_of_Finland.svg.png"
          style={{ heigth: "50px", width: "50px" }}
          className="flags-appbar langFlags"
        />
      </button>

      <h1>{t("Welcome to Reilu")}</h1> */}

      {/* toastify Container for Notification */}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        transition={Flip}
      />

      {/* Routes */}
      <Index />
    </div>
  );
}

// Export
export default App;
