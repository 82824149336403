import React from "react";
import "../../css/Blogs/BlogsSection.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InstaIcon from "../../assets/Icons/InstaIcon.svg";
import FbIcon from "../../assets/Icons/FbIcon.svg";
import TwitIcon from "../../assets/Icons/TwitIcon.svg";
import YtIcon from "../../assets/Icons/YtIcon.svg";
import BlogsBg from "../../assets/Image/BlogsBg.jpg";
import BlogsBgRes from "../../assets/Image/BlogsBgRes.jpg";

const BlogsSection = () => {
  return (
    <div>
      <Box sx={{ width: "100%" }} style={{ background: "#fff" }}>
        <Grid container className="blogs_center_div">
          <Grid item sm={6} xs={12} className="blogs_center_colmn">
            <div className="blogs_contents">
              {/* headings  */}
              <div className="blogs_home_headings">
                <h1 className="blogs_home_h1">Blogs</h1>
                <p className="blogs_home_p">
                  Reilu is a Turku-based startup that aims to provide a total
                  customer-centric food delivery service. Fairness and quality
                  service are paramount to us. Reilu is driven by passion to
                  helpReilu is a Turku-based startup that aims to provide a
                  total customer-centric food delivery service. Fairness and
                  quality service are paramount to us. Reilu is driven by
                  passion to helpRe
                </p>
              </div>
              {/* Social accounts  */}
              <div className="blogs_home_social_div">
                <img src={InstaIcon} alt="" className="blogs_home_social" />
                <img src={FbIcon} alt="" className="blogs_home_social" />
                <img src={TwitIcon} alt="" className="blogs_home_social" />
                <img src={YtIcon} alt="" className="blogs_home_social" />
              </div>
            </div>
            {/* Vlogs  */}
            <div className="blogs_vlogs_div">
              <a href="" className="blogs_all_vlogs">
                All Vlogs
              </a>
              <a href="" className="blogs_fav_blogs">
                Favourite Blogs
              </a>
            </div>
          </Grid>
          <Grid item xs={6} className="blogs_center_colmn">
            <div className="blogs_bg_div">
              <img src={BlogsBg} alt="" className="blogs_home_img" />
              <img src={BlogsBgRes} alt="" className="blogs_home_img2" />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BlogsSection;
