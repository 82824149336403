import React from "react";
import "../../css/Home/Works.css";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import AppStore from "../../assets/Icons/AppStore.svg";
import GooglePlay from "../../assets/Icons/GooglePlay.svg";
import findRestaurants from "../../assets/Image/findRestaurants.svg"
import placeorder from "../../assets/Image/placeorder.svg"
import selectfood from "../../assets/Image/selectfood.svg"
import { useTranslation } from "react-i18next";


const Works = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <div className="Works_main_div">
        <div className="Works_centerDiv">
          {/* headings  */}
          <div className="Maps_headings2">
            <p className="Maps_h12">{t("How Reilu works")}</p>
            <p className="maps_p">
              {t("Reilu makes the order flow simple for you.")}
            </p>
          </div>
          <div className="works_timeline_main_div">
            <div className="works_timeine_centerDiv">
              {/* timeLIne  */}
              <di className="timeLIne">
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <p className="works_title">{t("Download App")}</p>
                    <p className="works_title_p">
                      {t("You can download the Reilu's app from App store and Play store, where you can order your favorite meal from your local restaurants.")}
                    </p>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="works_android_div">
                      <p className="works_anroid_p">
                        {t("Available on iOS and Android")}
                      </p>

                      <div className="work_download_app_div">
                        <a href="https://reilukuljetus.fi/" target="_blank" rel="noopener noreferrer">
                          <img src={AppStore} alt="" className="works_apps" />
                        </a>
                        <a href="https://reilukuljetus.fi/" target="_blank" rel="noopener noreferrer">
                          <img src={GooglePlay} alt="" className="works_apps" />
                        </a>

                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <p className="works_title"> {t("Find Restaurants")}</p>
                    <p className="works_title_p">
                      {t("All your favourite restaurants are just a click away from you. Browse the app to choose best from the city!")}
                    </p>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="works_mobile_div">
                      <img
                        src={findRestaurants}
                        alt=""
                        className="works_mobile_img"
                      />
                    </div>
                  </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <p className="works_title"> {t("Select Food")}</p>
                    <p className="works_title_p">
                      {t("Your favourite food is just a click away from you now!")}
                    </p>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="works_mobile_div">
                      <img
                        src={selectfood}
                        alt=""
                        className="works_mobile_img"
                      />
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <p className="works_title"> {t("Place Order")}</p>
                    <p className="works_title_p">
                      {t("you can track your delivery partner reaches the destination")}
                    </p>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="works_mobile_div">
                      <img
                        src={placeorder}
                        alt=""
                        className="works_mobile_img"
                      />
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </di>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Works;
