import React from "react";
import "../../css/Restaurant/RestSection.css";
import RestaurantForm from "../../FormPopup/RestaurantForm";
import { useTranslation } from "react-i18next";


const RestSection = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const openAplyForm = () => {
    setOpen(true);
  };
  return (
    <>
      <RestaurantForm open={open} setOpen={setOpen} />
      <div className="RestSection">
        <div className="RestSection_center">
          <div className="RestSection_heading">
            <h1 className="RestSection_h1">
              {t("Become a Reilu's Restaurant Partner")}
            </h1>
            <div className="RestSection_box border border-danger">
              <div className="RestSection_box_content">
                <p className="RestSection_box_title">
                  {t("Boost your profit with fairer commissions")}
                </p>
                <p className="RestSection_box_p">
                  {t("Different restaurants have different needs and cost structures. This is why we want to negotiate with every restaurant to offer as fair as possible commission.")}
                </p>
              </div>
              <div className="RestSection_box_button_div">
                <button className="RestSection_box_btn" onClick={openAplyForm}>
                  {t("Apply Now")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestSection;
