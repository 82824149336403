import React from "react";
import "../../css/Home/Maps.css";
// import MapImg2 from "../../assets/Image/mapimgmain.svg";
// import MapImg2 from "../../assets/Image/MapImg2.svg";
import MapImg2 from "../../assets/Image/final_map.png";
import { useTranslation } from "react-i18next";



const Map2 = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <div className="OurStory ">
        <p className="OurStory_P">{t("Our story begins from Turku")}</p>
      </div>
      <div className="great_things ">
        <p className="great_things_P">{t("Great things come from great cities!")}</p>
      </div>

      <div className="MapMainDiv ">
        <div className="mapImgDiv " >
          <img className="mapImg " src={MapImg2} />
          
          
        </div>
        <div className="divone  "></div>
        <div className="divtwo "></div>
      </div>
    </>
  );
};

export default Map2;
