// Init
import React from "react";
import { Route, Switch } from "react-router-dom";
// Routes
import Home from "../containers/Home.js";
import AboutUs from "../containers/AboutUs.js";
import ContactUs from "../containers/ContactUs.js";
import FAQ from "../containers/FAQ.js";
import Blogs from "../containers/Blogs.js";
import News from "../containers/News.js";
import Blogs2 from "../containers/Blogs2.js";
import Driver from "../containers/Driver.js";
import Restaurant from "../containers/Restaurant.js";
import Privacy from "../containers/Privacy.js";
import Terms from "../containers/Terms.js";

const index = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={AboutUs} />
      <Route exact path="/contact" component={ContactUs} />
      {/* <Route exact path="/faq" component={FAQ} /> */}
      <Route exact path="/blogs" component={Blogs} />
      <Route exact path="/News" component={News} />
      <Route exact path="/Blogs2" component={Blogs2} />
      <Route exact path="/Driver" component={Driver} />
      <Route exact path="/Restaurant" component={Restaurant} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/terms" component={Terms} />
    </Switch>
  );
};

export default index;
