import React from "react";
import "../../css/Blogs/FoodBlogs.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// card
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import BlogsImg from "../../assets/Image/BlogsImg.svg";
import CardHeart from "../../assets/Icons/CardHeart.svg";
// pagination
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CardData = [
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
  {
    title: "Lorem ipsum",
    title2: "By Bilal Ahmed August 11, 2021",
    content:
      " Reilu is a Turku-based startup that aims to provReilu is a Turku-based startup that aims to prov",
  },
];

const FoodBlogs = (props) => {
  return (
    <>
      <div className="foodBlogs_main_div">
        <Box className="food_blogs_main">
          <div className="food_title">
            <p className="food_title_h1">{props.title}</p>
          </div>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {CardData.map((item) => {
              return (
                <Grid item sm={4} xs={12}>
                  {/* card  */}

                  <Card>
                    <CardMedia
                      component="img"
                      image={BlogsImg}
                      alt="green iguana"
                    />
                    <CardContent className="food_blogsCard">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="card_heading"
                      >
                        <div>
                          <p className="card_title">{item.title}</p>
                          <p className="card_title2">{item.title2}</p>
                        </div>
                        <img src={CardHeart} alt="" className="card_heart" />
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <p className="card_title_p"> {item.content}</p>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <button className="food_card_BTN">Read More</button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <div className="food_pagination">
            <Stack spacing={2}>
              <Pagination
                count={10}
                siblingCount={0}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
        </Box>
      </div>
    </>
  );
};

export default FoodBlogs;
