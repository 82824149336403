// Init
import React, { useEffect, useState } from "react";

// Importing Components
import Header from "../components/Home/Header";
import HomeSection from "../components/Home/HomeSection";
import Delivery from "../components/Home/Delivery";
// import Maps from "../components/Home/Maps";
import Works from "../components/Home/Works";
import DriversCard from "../components/Home/DriversCard";
import Footer from "../components/Home/Footer";
import Map2 from "../components/Home/Map2";
import HeaderCard from "../components/HeaderCard/HeaderCard";
import WhyReiluSection from "../components/Home/WhyReiluSection";
import { useTranslation } from "react-i18next";

// Home Component
export default function Home() {

  React.useState(false);
  const { i18n, } = useTranslation();
  const [check, setcheck] = useState(true)
  useEffect(() => {
    if (check === false) {
      i18n.changeLanguage("en")
    }
    else if (check === true) {
      i18n.changeLanguage("fr")
    }

  }, [check])

  const scrollToTop = () => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
  };
  React.useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <Header check={check} setcheck={setcheck} />
      <HomeSection check={check} setcheck={setcheck} />
      <HeaderCard />

      <Delivery />
      {/* <Maps /> */}
      <Map2 />
      <WhyReiluSection />
      <Works />
      <DriversCard />
      <Footer check={check} setcheck={setcheck} />

    </>
  );
}
