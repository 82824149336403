import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // fallbackLng: ["fr", "en"],
    // we init with resources
    resources: {
      en: {
        translations: {
          "Welcome to Reilu": "Welcome to Reilu",

          "Reilu drivers are members of a cooperative which enables them to have a say in operations.": "Reilu drivers are members of a cooperative which enables them to have a say in operations.",
          "ORDER FOOD WITH A GOOD CONSCIENCE!": "ORDER FOOD WITH A GOOD CONSCIENCE!",
          "New food delivery service in Turku": "New food delivery service in Turku",
          "Our story begins from Turku": "Our story begins from Turku",
          "Great things come from great cities!": "Great things come from great cities!",
          "Fair delivery": "Fair delivery",
          "Reilu is a Turku-based startup that aims to provide a total customer-centric food delivery service. Fairness and quality service are paramount to us. Reilu is driven by passion to help the local food and beverage ecosystem thrive in the post-pandemic world. Our core values that define us include transparency, empowering small local businesses and providing fair working conditions to our delivery partners. We are a bunch of enthusiastic and friendly folk. We love hearing from you and believe in co-creating the service as per the wishes of the customers. Reilu offers fairer contracts to our delivery partners that make them a part of our company through a cooperative business model": "Reilu is a Turku-based startup that aims to provide a total customer-centric food delivery service. Fairness and quality service are paramount to us. Reilu is driven by passion to help the local food and beverage ecosystem thrive in the post-pandemic world. Our core values that define us include transparency, empowering small local businesses and providing fair working conditions to our delivery partners. We are a bunch of enthusiastic and friendly folk. We love hearing from you and believe in co-creating the service as per the wishes of the customers. Reilu offers fairer contracts to our delivery partners that make them a part of our company through a cooperative business model",
          "Why Reilu": "Why Reilu",
          "Turku-based Reilu kuljetus delivers in a fair way! We make a fair contract with all our couriers and offer a fair commission to all our partner restaurants – without compromising quality and speed. First and foremost we care about our customers so you can concentrate on what is the most important for you. Reilu kuljetus delivers always more than you expected. Delivering with fairness and care ⏤ without compromising speed and quality": "Turku-based Reilu kuljetus delivers in a fair way! We make a fair contract with all our couriers and offer a fair commission to all our partner restaurants – without compromising quality and speed. First and foremost we care about our customers so you can concentrate on what is the most important for you. Reilu kuljetus delivers always more than you expected. Delivering with fairness and care ⏤ without compromising speed and quality",

          "Compensate your CO2 emissions – for the nature.": "Compensate your CO2 emissions – for the nature.",

          "How Reilu works": "How Reilu works",

          "Reilu makes the order flow simple for you.": "Reilu makes the order flow simple for you.",

          "Download App": "Download App",

          "You can download the Reilu's app from App store and Play store, where you can order your favorite meal from your local restaurants.": "You can download the Reilu's app from App store and Play store, where you can order your favorite meal from your local restaurants.",

          "Find Restaurants": "Find Restaurants",

          "All your favourite restaurants are just a click away from you. Browse the app to choose best from the city!": "All your favourite restaurants are just a click away from you. Browse the app to choose best from the city!",

          "Select Food": "Select Food",

          "Your favourite food is just a click away from you now!": "Your favourite food is just a click away from you now!",

          "Place Order": "Place Order",
          "Drivers": "Drivers",

          "you can track your delivery partner reaches the destination": "you can track your delivery partner reaches the destination",

          "You can download the apps.": "You can download the apps.",

          "Reilu is a Turku-based startup that aims to provide a solution for the customers to order food with good conscience.": "Reilu is a Turku-based startup that aims to provide a solution for the customers to order food with good conscience.",

          "Get to know us": "Get to know us",
          "About us": "About us",

          "Contact us": "Contact us",
          "Blogs": "Blogs",
          "News": "News",

          "Contact info": "Contact info",
          "Finland": "Finland",
          "Partners": "Partners,",
          "Driver": "Driver",
          "Restaurant": "Restaurant",
          "News Letter": "News Letter",
          "Sign up for our newsletter and be the first to know about our special offers!": "Sign up for our newsletter and be the first to know about our special offers!",

          "Subscribe": "Subscribe",
          "Finnish": "Suomi",

          "Available on iOS and Android": "Available on iOS and Android",

          "Available Now": "Available Now",
          "Restaurants": "Restaurants",
          "Environment": "Environment",
          "Reilu makes fair and just contracts with all partners.": "Reilu makes fair and just contracts with all partners.",

          "It’s important for us that you can compensate the co2 emissions of your food production and delivery.": "It’s important for us that you can compensate the co2 emissions of your food production and delivery.",
          "Jobs": "Jobs",
          "Grow your business and reach more customers by partnering with us": "Grow your business and reach more customers by partnering with us",
          "Start a career with Reilu and make a change.": "Start a career with Reilu and make a change.",
          "Reilu is a Turku-based startup that aims to provide a total customer-centric food delivery service. Fairness and quality service are paramount to us. Reilu is driven by passion to help the local food and beverage ecosystem thrive in the post-pandemic world. Our core values that define us include transparency, empowering small local businesses and providing fair working conditions to our delivery partners. We are a bunch of enthusiastic and friendly folk. We love hearing from you and believe in co-creating the service as per your wishes. Reilu offers fairer contracts to our delivery partners that make them a part of our company through a cooperative business model.": "Reilu is a Turku-based startup that aims to provide a total customer-centric food delivery service. Fairness and quality service are paramount to us. Reilu is driven by passion to help the local food and beverage ecosystem thrive in the post-pandemic world. Our core values that define us include transparency, empowering small local businesses and providing fair working conditions to our delivery partners. We are a bunch of enthusiastic and friendly folk. We love hearing from you and believe in co-creating the service as per your wishes. Reilu offers fairer contracts to our delivery partners that make them a part of our company through a cooperative business model.",
          "Become a part of a mission to change the way how delivery business works": "Become a part of a mission to change the way how delivery business works",
          //  driver page 
          "Fair deliveries Fair contracts": "Fair deliveries Fair contracts",
          "Do not promise/bind us to this": "Do not promise/bind us to this",
          "Be your own boss": "Be your own boss",
          "Apply Now": "Apply Now",

          "Become a Reilu Driver and": "Become a Reilu Driver and",
          "Get a fair contract": "Get a fair contract",
          "On top of the compensation you can also be a member of the cooperative company as an entrepreneur"
            : "On top of the compensation you can also be a member of the cooperative company as an entrepreneur",
          "Work when you want": "Work when you want",
          "The hours are flexible. Even when you are working with employee status, you have freedom to work whenever suits you best."
            : "The hours are flexible. Even when you are working with employee status, you have freedom to work whenever suits you best.",
          "In Reilu we are a community and we do things together. You have also possibility to earn more by doing administrative tasks on the side of deliveries."
            : "In Reilu we are a community and we do things together. You have also possibility to earn more by doing administrative tasks on the side of deliveries.",

          "Reilu is all about Fairness": "Reilu is all about Fairness",
          "Reilu is on mission of making food delivery business as a career job. As a Reilu driver, you will be part of Reilu's drivers' cooperative which provides fairer work contracts and commissions to the delivery partners that ensure sustainable profits for both drivers and restaurants."
            : "Reilu is on mission of making food delivery business as a career job. As a Reilu driver, you will be part of Reilu's drivers' cooperative which provides fairer work contracts and commissions to the delivery partners that ensure sustainable profits for both drivers and restaurants.",
          "Fill in your application": "Fill in your application",
          "In a matter of minutes, take the a step towards a better business deal"
            : "In a matter of minutes, take the a step towards a better business deal",
          "Get ready to start": "Get ready to start",
          "Get your delivery gear for free": "Get your delivery gear for free",
          "Get brief introduction on the job's requirements"
            : "Get brief introduction on the job's requirements",
          "Learn the trade": "Learn the trade",
          "Gear Up": "Gear Up",
          "Gear up and start the deliveries": "Gear up and start the deliveries",
          "Hear from our first batch of courier partners": "Hear from our first batch of courier partners",
          "Reading through Reilu's courier terms, I'm excited to be a part of this organization. The team seems super friendly and I'm looking forward to a great partnership going forward [Basit]"
            : "Reading through Reilu's courier terms, I'm excited to be a part of this organization. The team seems super friendly and I'm looking forward to a great partnership going forward [Basit]",

          "How can we help?": "How can we help?",
          "When you are on the road, we are always there for you to help, advice and support when needed. Just message us on the app!"
            : "When you are on the road, we are always there for you to help, advice and support when needed. Just message us on the app!",
          // Restaurant
          "Become a Reilu's Restaurant Partner": "Become a Reilu's Restaurant Partner",
          "Boost your profit with fairer commissions": "Boost your profit with fairer commissions",
          "Different restaurants have different needs and cost structures. This is why we want to negotiate with every restaurant to offer as fair as possible commission."
            : "Different restaurants have different needs and cost structures. This is why we want to negotiate with every restaurant to offer as fair as possible commission.",

          "Why Reilu?": "Why Reilu?",
          "Fair Commission": "Fair Commission",
          "Make sure you get the best possible commission to boost your profits from food deliveries."
            : "Make sure you get the best possible commission to boost your profits from food deliveries.",
          "Locality": "Locality",
          "As a local business we create close relationships with our partners to offer best possible customer service."
            : "As a local business we create close relationships with our partners to offer best possible customer service.",
          "Interaction with your customers:": "Interaction with your customers:",
          "We make sure you can communicate with your customers, reply customer feedbacks and answer possible complains."
            : "We make sure you can communicate with your customers, reply customer feedbacks and answer possible complains.",

          "How Reilu works?": "How Reilu works?",
          "The Reilu delivery and pickup process is quick and easy."
            : "The Reilu delivery and pickup process is quick and easy.",
          "Fill in your application": "Fill in your application",
          "Customers make orders on Reilu's app or website.": "Customers make orders on Reilu's app or website.",
          "Get a Free App": "Get a Free App",
          "You will receive order from the customer on your phone and tablet": "You will receive order from the customer on your phone and tablet",
          "Sign a contract": "Sign a contract",
          "You will recieve a call and an online contract. Review and sign."
            : "You will recieve a call and an online contract. Review and sign.",
          "Make your customers Happy": "Make your customers Happy",
          "Work with us to provide the same experience what they get at your restaurant"
            : "Work with us to provide the same experience what they get at your restaurant",
          "How can we help?": "How can we help?",
          "Let us know how we could make our service better and more suitable for your needs (info@reilukuljetus.fi"
            : "Let us know how we could make our service better and more suitable for your needs (info@reilukuljetus.fi",

          "Restaurant app": "Restaurant app",
          "Our restaurant app keeps your orders in track. We will provide the equipment for you free of charge."
            : "Our restaurant app keeps your orders in track. We will provide the equipment for you free of charge.",

          "Become a Reilu Driver and..": "Become a Reilu Driver and..",
          "When you are on the road, we are always there for you to help, advice and support when needed. Just message us on the app!"
            : "When you are on the road, we are always there for you to help, advice and support when needed. Just message us on the app!",
          "How can we help?": "How can we help?",
          "Restaurant app": "Restaurant app",
          "Our restaurant app keeps your orders in track. We will provide the equipment for you free of charge."
            : "Our restaurant app keeps your orders in track. We will provide the equipment for you free of charge.",

          "Read more": "Read more",
          "Show less": "Show less",
          "(Read more)": "(Read more)",
          "(Show less)": "(Show less)",
          "Watch Now": "Watch Now",

          // popup
          "Driver Registration Form": "Driver Registration Form",
          "First name": "First name",
          "Mobile number": "Mobile number",
          "Your Vehicle": "Your Vehicle",
          "Last Name": "Last Name",
          "E-Email": "E-Email",
          "Language": "Language",
          "Do you have a valid work permit?": "Do you have a valid work permit?",
          "Yes": "Yes",
          "No": "No",
          "Do you have a driving license?": "Do you have a driving license?",
          "I've read and agree with the User": "I've read and agree with the User",

          "Restaurant Registration Form": "Restaurant Registration Form",
          "Restaurant Nam": "Restaurant Nam",
          "Number": "Number",
          "Business ID": "Business ID",
          "Email": "Email",
          "I agree to the Reilu's": "I agree to the Reilu's",
          "privacy policy.": "privacy policy.",
          // footer
          "Delivigo oy 2023 ALL Right reserved": "Delivigo oy 2023 ALL Right reserved",
          "Privacy Policy": "Privacy Policy",
          "Terms & Conditions": "Terms & Conditions",

          // About
          "What we are?": "What we are?",
          "Reilu kuljetus is a Turku-based startup that brings the best of the local restaurants to your doorstep in a fair and premium manner. Behind the scenes,"
            : "Reilu kuljetus is a Turku-based startup that brings the best of the local restaurants to your doorstep in a fair and premium manner. Behind the scenes,",
          'Reilu is run by a team of professional enthusiasts who aim at making the food delivery service more ethical and sustainable. Our delivery partners are actually decision makers in Reilu’s model. Together with the best local restaurant partners, we deliver in a more ethical and responsible manner towards the environment. Above all, Reilu takes the safety of your data seriously and does not charge the customers any extra to enjoy the premium service. We are a fully approachable team and would always love to hear from you, find more about us [here.]'
            : 'Reilu is run by a team of professional enthusiasts who aim at making the food delivery service more ethical and sustainable. Our delivery partners are actually decision makers in Reilu’s model. Together with the best local restaurant partners, we deliver in a more ethical and responsible manner towards the environment. Above all, Reilu takes the safety of your data seriously and does not charge the customers any extra to enjoy the premium service. We are a fully approachable team and would always love to hear from you, find more about us [here.]',
          "Reilu is run by a team of professional enthusiasts who aim at making the food delivery service more ethical and sustainable. Our delivery partners are actually decision makers in Reilu’s model. Together with the best local restaurant partners, we deliver in a more ethical and responsible manner towards the environment. Above all, Reilu takes the safety of your data seriously and does not charge the customers any extra to enjoy the premium service. We are a fully approachable team and would always love to hear from you, find more about us"
            : "Reilu is run by a team of professional enthusiasts who aim at making the food delivery service more ethical and sustainable. Our delivery partners are actually decision makers in Reilu’s model. Together with the best local restaurant partners, we deliver in a more ethical and responsible manner towards the environment. Above all, Reilu takes the safety of your data seriously and does not charge the customers any extra to enjoy the premium service. We are a fully approachable team and would always love to hear from you, find more about us",

          "Milestones": "Milestones",
          "Before expanding our operations to other cities, we would like to improve our services to better match the needs of our customers. By 2023, we aim to start serving customers from other Finnish cities too. We would love our customers to share their views about our service and help us in co-creating a truly customer-centric service."
            : "Before expanding our operations to other cities, we would like to improve our services to better match the needs of our customers. By 2023, we aim to start serving customers from other Finnish cities too. We would love our customers to share their views about our service and help us in co-creating a truly customer-centric service.",
          "Expanding to two new cities in 2023": "Expanding to two new cities in 2023",
          "The Launch": "The Launch",
          "Reilu is TURKULAINEN. In the last few months, we have been grinding to make our services available to the good citizens of Turku. We have made fair contracts with both restaurant and delivery partners. We are done with our dry runs & beta testing, and we are all ready to hit the streets of Turku."
            : "Reilu is TURKULAINEN. In the last few months, we have been grinding to make our services available to the good citizens of Turku. We have made fair contracts with both restaurant and delivery partners. We are done with our dry runs & beta testing, and we are all ready to hit the streets of Turku.",
          "Expanding from food delivery to other goods": "Expanding from food delivery to other goods",
          "Reilu is TURKULAINEN. In the last few months, we have been grinding to make our services available to the good citizens of Turku. We have made fair contracts with both restaurant and delivery partners. We are done with our dry runs & beta testing, and we are all ready to hit the streets of Turku."
            : "Reilu is TURKULAINEN. In the last few months, we have been grinding to make our services available to the good citizens of Turku. We have made fair contracts with both restaurant and delivery partners. We are done with our dry runs & beta testing, and we are all ready to hit the streets of Turku.",

          "Our Brilliant team is here to help": "Our Brilliant team is here to help",
          "Founder": "Founder",
          "Rufus Vinod is a Ph.D student and an entrepreneur. During Ph.D at university of Turku he started working as a courier partner in a delivery company. He felt the need of a delivery system that would provide an provide better wording conditions for the couriers and provide customers with a local and provides the customer a local platform.Reilu is a Finnish company and TURKU will always be our home."
            : "Rufus Vinod is a Ph.D student and an entrepreneur. During Ph.D at university of Turku he started working as a courier partner in a delivery company. He felt the need of a delivery system that would provide an provide better wording conditions for the couriers and provide customers with a local and provides the customer a local platform.Reilu is a Finnish company and TURKU will always be our home.",
          "Lauri Koittola is a serial entrepreneur and a team coach, who ended up as a business partner through a common friend. In the beginning Lauri’s main responsibility was connections to restaurants and media. After launching the business the emphasis shifted to coaching the team with self-managing methods. Lauri is also responsible for the matters regarding cooperative business model."
            : "Lauri Koittola is a serial entrepreneur and a team coach, who ended up as a business partner through a common friend. In the beginning Lauri’s main responsibility was connections to restaurants and media. After launching the business the emphasis shifted to coaching the team with self-managing methods. Lauri is also responsible for the matters regarding cooperative business model.",

          "About Reilu": "About Reilu",
          "Reilu kuljetus is Rufus’ brainchild. Rufus is a cancer researcher and a part-time courier who brings joy to hungry Turku citizens. While working as a courier, he realized how little the couriers were able to influence their working conditions. It’s about time things changed. He gathered a team of enthusiasts to create a customer-centric food delivery service which helps the small local businesses sustain while creating better working conditions for the delivery partners."
            : "Reilu kuljetus is Rufus’ brainchild. Rufus is a cancer researcher and a part-time courier who brings joy to hungry Turku citizens. While working as a courier, he realized how little the couriers were able to influence their working conditions. It’s about time things changed. He gathered a team of enthusiasts to create a customer-centric food delivery service which helps the small local businesses sustain while creating better working conditions for the delivery partners.",
          "Lauri was invited first to join and become a business partner in the company. He’s a natural people’s person making him an invaluable asset to the company who networks with the local restaurants and the media."
            : "Lauri was invited first to join and become a business partner in the company. He’s a natural people’s person making him an invaluable asset to the company who networks with the local restaurants and the media.",
          "We are expanding. We now have service designers who are working round the clock making the service as customer-oriented as possible. We look at our customers not just as someone who pay us for our service, but also someone who help us in developing our services further. With Reilu kuljetus, you are not just customers, you are co-creators."
            : "We are expanding. We now have service designers who are working round the clock making the service as customer-oriented as possible. We look at our customers not just as someone who pay us for our service, but also someone who help us in developing our services further. With Reilu kuljetus, you are not just customers, you are co-creators.",

          "Contact us": "Contact us",
          "Email address": "Email address",
          "Subject": "Subject",
          "Comments": "Comments",
          "Submit": "Submit",

          "Reilu for New": "Reilu for New",
          "Restaurants": "Restaurants",
          "Are you a restaurant owner and interested in joining Reilu? let’s get in touch, we’d love to show how partnering with Reilu could help you grow your business."
            : "Are you a restaurant owner and interested in joining Reilu? let’s get in touch, we’d love to show how partnering with Reilu could help you grow your business.",
          "Support for": "Support for",
          "You can access our Help Center for Restaurants directly from your Reilu’s restaurant app. Sign in to your Reilu restaurant app and click on “Help center” for information on how to update your menu, adjust your opening hours, and more."
            : "You can access our Help Center for Restaurants directly from your Reilu’s restaurant app. Sign in to your Reilu restaurant app and click on “Help center” for information on how to update your menu, adjust your opening hours, and more.",
          "Reilu kuljetus is Rufus’ brainchild. Rufus is a cancer researcher and a part-time courier who brings joy to Turku citizens. While working as a courier, he realized how little the couriers were able to influence their working conditions.It’s about time things changed. He gathered a team of enthusiasts to create a fair food delivery service which helps the small local businesses to sustain while creating better working conditions for the delivery partners and restaurants."
            : "Reilu kuljetus is Rufus’ brainchild. Rufus is a cancer researcher and a part-time courier who brings joy to Turku citizens. While working as a courier, he realized how little the couriers were able to influence their working conditions.It’s about time things changed. He gathered a team of enthusiasts to create a fair food delivery service which helps the small local businesses to sustain while creating better working conditions for the delivery partners and restaurants."
          ,
          "We are expanding. We now have service designers who are working round the clock making the service as friendly as possible. We look at our customers not just as someone who pay us for our service, but also someone who helps us in developing our services further. With Reilu kuljetus, you are not just customers, you are co-creators."
            : "We are expanding. We now have service designers who are working round the clock making the service as friendly as possible. We look at our customers not just as someone who pay us for our service, but also someone who helps us in developing our services further. With Reilu kuljetus, you are not just customers, you are co-creators.",
          "Do you want to contact us? Great, you can do it using the form below. We are happy to hear thoughts, development proposals and general feedback! Fair transport is for you, so your opinion is extremely important to us."
            : "Do you want to contact us? Great, you can do it using the form below. We are happy to hear thoughts, development proposals and general feedback! Fair transport is for you, so your opinion is extremely important to us.",
          "Name": "Name",
          "": "",
          "": "",
        },
      },
      suomi: {
        translations: {
          "Start a career with Reilu and make a change.": "Tule meille töihin ja tee merkityksellistä työtä, itsesi, yhteiskunnan, ravintoloiden ja asiakkaidemme parhaaksi. Me olemme reilu työnantaja!",
          "Welcome to Reilu": "Tervetuloa Reilulle",
          "Grow your business and reach more customers by partnering with us": "Kasvata liiketoimintaasi ryhtymällä kumppaniksemme ja tarjoamalla reilun ruoan kuljetuksen asiakkaillesi.",
          "Become a part of a mission to change the way how delivery business works": "Tule kumppaniksemme muuttamaan ruokakuljetusalaa! Osuuskunnan jäseneä sinulla on mahdollisuus vaikuttaa.",
          "Jobs": "Työpaikat",
          "Reilu is a Turku-based startup that aims to provide a total customer-centric food delivery service. Fairness and quality service are paramount to us. Reilu is driven by passion to help the local food and beverage ecosystem thrive in the post-pandemic world. Our core values that define us include transparency, empowering small local businesses and providing fair working conditions to our delivery partners. We are a bunch of enthusiastic and friendly folk. We love hearing from you and believe in co-creating the service as per your wishes. Reilu offers fairer contracts to our delivery partners that make them a part of our company through a cooperative business model.": "Reilu on turkulainen startup, jonka tavoitteena on tarjota kokonaisvaltainen asiakaslähtöinen ruoan toimituspalvelu. Reiluus ja laadukas palvelu ovat meille ensiarvoisen tärkeitä. Reilua ohjaa intohimo auttaa paikallista ruoka- ja juomaekosysteemiä menestymään pandemian jälkeisessä maailmassa. Meidät määritteleviä perusarvojamme ovat läpinäkyvyys, pienten paikallisten yritysten voimaannuttaminen ja oikeudenmukaisten työolojen tarjoaminen toimituskumppaneillemme. Olemme joukko innokkaita ja ystävällisiä ihmisiä. Haluamme kuulla sinusta ja uskomme, että voimme yhdessä luoda palvelun toiveidesi mukaan. Reilu tarjoaa toimituskumppaneillemme reilumpia sopimuksia, jotka tekevät heistä osa yritystämme yhteistoiminnallisen liiketoimintamallin kautta.",
          "Restaurants": "Ravintolat",
          "Support for": "Tukea: lle",
          "Reilu for New": "Reilu uudelle",
          "Environment": "Ympäristö",
          "Drivers": "Kuljettajat",
          "You can access our Help Center for Restaurants directly from your Reilu’s restaurant app. Sign in to your Reilu restaurant app and click on “Help center” for information on how to update your menu, adjust your opening hours, and more."
            : "Pääset ravintoloiden ohjekeskukseemme suoraan Reilun ravintolasovelluksesta. Kirjaudu sisään Reilu-ravintolasovellukseesi ja klikkaa ”Ohjekeskus” saadaksesi tietoa ruokalistan päivittämisestä, aukioloaikojen säätämisestä ja paljon muuta.",
          "Are you a restaurant owner and interested in joining Reilu? let’s get in touch, we’d love to show how partnering with Reilu could help you grow your business."
            : "Oletko ravintolan omistaja ja kiinnostunut liittymään Reilun joukkoon? Ota yhteyttä, näytämme mielellämme, kuinka yhteistyö Reilun kanssa voi auttaa sinua kasvattamaan liiketoimintaasi.",
          "It’s important for us that you can compensate the co2 emissions of your food production and delivery.": "Meille on tärkeää, että voit halutessasi kompensoida ruuan valmistuksen ja kuljetuksen CO2-päästöt",
          "Reilu makes fair and just contracts with all partners.": "Reilu tekee reilut ja oikeudenmukaiset sopimukset kaikkien kumppaneiden kanssa.",
          "Reilu drivers are members of a cooperative which enables them to have a say in operations.": "Reilu kuljetuksen kuljettajat ovat osa osuuskuntaa, jossa he voivat aidosti yrittäjinä vaikuttaa päätöksentekoon",
          "ORDER FOOD WITH A GOOD CONSCIENCE!": "Tilaa ruokaa hyvällä omalla-tunnolla!",
          "New food delivery service in Turku": "Uusi vastuullisempi ruokakuljetuspalvelu on aloittanut Turussa",
          "Our story begins from Turku": "Tarinamme alkaa Turusta",
          "Great things come from great cities!": "Hienoja asioita tulee upeista kaupungeista!",
          "Fair delivery": "Reilu toimitus",
          "Reilu is a Turku-based startup that aims to provide a total customer-centric food delivery service. Fairness and quality service are paramount to us. Reilu is driven by passion to help the local food and beverage ecosystem thrive in the post-pandemic world. Our core values that define us include transparency, empowering small local businesses and providing fair working conditions to our delivery partners. We are a bunch of enthusiastic and friendly folk. We love hearing from you and believe in co-creating the service as per the wishes of the customers. Reilu offers fairer contracts to our delivery partners that make them a part of our company through a cooperative business model": "Reilu on turkulainen startup, jonka tavoitteena on tarjota kokonaisvaltainen asiakaslähtöinen ruoan toimituspalvelu. Reiluus ja laadukas palvelu ovat meille ensiarvoisen tärkeitä. Reilua ohjaa intohimo auttaa paikallista ruoka- ja juomaekosysteemiä menestymään pandemian jälkeisessä maailmassa. Meidät määritteleviä perusarvojamme ovat läpinäkyvyys, pienten paikallisten yritysten voimaannuttaminen ja oikeudenmukaisten työolojen tarjoaminen toimituskumppaneillemme. Olemme joukko innokkaita ja ystävällisiä ihmisiä. Haluamme kuulla sinusta ja uskomme, että voimme yhdessä luoda palvelun asiakkaiden toiveiden mukaisesti. Reilu tarjoaa toimituskumppaneillemme reilumpia sopimuksia, jotka tekevät heistä osa yritystämme yhteistoiminnallisen liiketoimintamallin kautta",
          "Why Reilu": "Reilua välittämistä",
          "Turku-based Reilu kuljetus delivers in a fair way! We make a fair contract with all our couriers and offer a fair commission to all our partner restaurants – without compromising quality and speed. First and foremost we care about our customers so you can concentrate on what is the most important for you. Reilu kuljetus delivers always more than you expected. Delivering with fairness and care ⏤ without compromising speed and quality": "Turkulainen Reilu kuljetus syntyi halusta toimia vastuullisesti, läpinäkyvästi sekä reilusti niin työntekijöitään, asiakkaitaan kuin ravintoloitakin kohtaan. Ruoan kuljetusalalla on paljon erlaisia epäkohtia, joita me haluamme omalla toiminnallamme muuttaa. Voit lukea lisää tästä blogissamme.Reilua välittämistä⏤laadusta ja nopeudesta tinkimättä!",

          "Compensate your CO2 emissions – for the nature.": "Kompensoi hiilidioksidipäästösi – ympäristön parhaaksi.",

          "How Reilu works": "Miten Reilu toimii?",
          "Reilu makes the order flow simple for you.": "Reilu tekee tilauksen sujumisesta helppoa.",

          "Download App": "Lataa sovellus",

          "You can download the Reilu's app from App store and Play store, where you can order your favorite meal from your local restaurants.": "Voit ladata Reilu’n sovelluksen Appstoresta ja Playstoresta.",


          "Find Restaurants": "Löydä ravintola",

          "All your favourite restaurants are just a click away from you. Browse the app to choose best from the city!": "Lempiravintolasi ovat sovelluksessa vain yhden klikkauksen päässä.",

          "Select Food": "Valitse ruoka",

          "Your favourite food is just a click away from you now!": "Valitse laajasta valikoimasta mieleisesi ateria joko herkutteluun tai tervelliseen ruokailuun.",

          "Place Order": "Tee tilaus",

          "you can track your delivery partner reaches the destination": "voit seurata toimituskumppanisi saapumista määränpäähän",

          "You can download the apps.": "Lataa sovellus jo tänään",

          "Reilu is a Turku-based startup that aims to provide a solution for the customers to order food with good conscience.": "Tilaa ruokaa hyvällä omallatunnolla! Reilu kuljetus on turkulainen vastuullisempi ruokakuljetussovellus.",
          "Get to know us": "Lue lisää Reilusta",
          "About us": "Tietoa meistä",
          "Contact us": "Ota yhteys",
          "Blogs": "Blogit",
          "News": "Ajankohtaista",
          "Contact info": "Yhteystiedot",

          "Finland": "Suomi",

          "Partners": "Kumppanit",
          "Driver": "Kuljettajat",
          "Restaurant": "Ravintolat",
          "News Letter": "Tilaa uutiskirje",
          "Sign up for our newsletter and be the first to know about our special offers!": "Tilaa uutiskirjeemme niin pysyt kärryillä ajankohtaisista asioista ja tarjouksista.",
          "Subscribe": "Tilaa",
          "Finnish": "Suomi",
          "Available on iOS and Android": "Saatavilla sekä Androidille että iOS:lle",
          "Available Now": "Lataa nyt",
          //  driver page 
          "Fair deliveries Fair contracts": "Reilut toimitukset Reilut sopimukset",
          "Do not promise/bind us to this": "Älä lupaa/sido meitä tähän",
          "Be your own boss": "Ole itsesi pomo",
          "Apply Now": "Hae nyt",
          "Become a Reilu Driver and": "Ryhdy Reilu-kuljettajaksi ja",
          "Get a fair contract": "Hanki reilu sopimus",
          "On top of the compensation you can also be a member of the cooperative company as an entrepreneur"
            : "Korvauksen lisäksi voit olla myös osuuskunnan jäsen yrittäjänä",
          "Work when you want": "Työskentele milloin haluat",
          "The hours are flexible. Even when you are working with employee status, you have freedom to work whenever suits you best."
            : "Työajat ovat joustavia. Vaikka työskentelet työntekijän asemassa, sinulla on vapaus työskennellä silloin, kun sinulle parhaiten sopii.",
          "In Reilu we are a community and we do things together. You have also possibility to earn more by doing administrative tasks on the side of deliveries."
            : "Reilussa olemme yhteisö ja teemme asioita yhdessä. Sinulla on myös mahdollisuus ansaita enemmän hoitamalla hallinnollisia tehtäviä toimitusten puolella.",

          "Reilu is all about Fairness": "Reilussa on kyse oikeudenmukaisuudesta",
          "Reilu is on mission of making food delivery business as a career job. As a Reilu driver, you will be part of Reilu's drivers' cooperative which provides fairer work contracts and commissions to the delivery partners that ensure sustainable profits for both drivers and restaurants."
            : "Reilun missio on tehdä ruuantoimitusliiketoiminnasta uratyönä. Reilun kuljettajana tulet osaksi Reilun kuljettajaosuuskuntaa, joka tarjoaa toimituskumppaneille reilummat työsopimukset ja palkkiot, jotka takaavat kestävän tuoton niin kuljettajille kuin ravintoloillekin.",
          "Fill in your application": "Täytä hakemuksesi",
          "In a matter of minutes, take the a step towards a better business deal"
            : "Ota muutamassa minuutissa askel kohti parempaa liikesopimusta",
          "Get ready to start": "Valmistaudu aloittamaan",
          "Get your delivery gear for free": "Hanki toimitusvarusteesi ilmaiseksi",
          "Get brief introduction on the job's requirements"
            : "Saat lyhyen esittelyn työn vaatimuksista",
          "Learn the trade": "Opi ammatti",
          "Gear Up": "Varustautua",
          "Gear up and start the deliveries": "Valmistaudu ja aloita toimitukset",
          "Hear from our first batch of courier partners": "Kuule ensimmäiset kuriirikumppanimme",
          "Reading through Reilu's courier terms, I'm excited to be a part of this organization. The team seems super friendly and I'm looking forward to a great partnership going forward [Basit]"
            : "Reilun kuriiriehtoja lukiessani olen innoissani, että saan olla osa tätä organisaatiota. Joukkue vaikuttaa erittäin ystävälliseltä ja odotan innolla tulevaa loistavaa kumppanuutta [Basit]",

          "How can we help?": "Kuinka voimme auttaa?",
          "When you are on the road, we are always there for you to help, advice and support when needed. Just message us on the app!"
            : "Kun olet tien päällä, olemme aina valmiina auttamaan, neuvomaan ja tukemaan tarvittaessa. Laita meille viestiä sovelluksessa!",
          // Restaurant
          "Become a Reilu's Restaurant Partner": "Ryhdy Reilun ravintolakumppaniksi",
          "Boost your profit with fairer commissions": "Kasvata voittoasi oikeudenmukaisemmilla palkkioilla",
          "Different restaurants have different needs and cost structures. This is why we want to negotiate with every restaurant to offer as fair as possible commission."
            : "Eri ravintoloilla on erilaiset tarpeet ja kustannusrakenteet. Tästä syystä haluamme neuvotella jokaisen ravintolan kanssa tarjotaksemme mahdollisimman oikeudenmukaisen välityspalkkion.",

          "Why Reilu?": "Miksi Reilu?",
          "Fair Commission": "Reilu komissio",
          "Make sure you get the best possible commission to boost your profits from food deliveries."
            : "Varmista, että saat parhaan mahdollisen palkkion lisätäksesi voittojasi ruokatoimituksista.",
          "Locality": "Sijainti",
          "As a local business we create close relationships with our partners to offer best possible customer service."
            : "Paikallisena yrityksenä luomme läheiset suhteet kumppaneidemme kanssa tarjotaksemme parasta mahdollista asiakaspalvelua.",
          "Interaction with your customers:": "Vuorovaikutus asiakkaidesi kanssa:",
          "We make sure you can communicate with your customers, reply customer feedbacks and answer possible complains."
            : "Varmistamme, että pystyt kommunikoimaan asiakkaidesi kanssa, vastaamaan asiakkaiden palautteisiin ja mahdollisiin valituksiin.",

          "How Reilu works?": "Miten Reilu toimii?",
          "The Reilu delivery and pickup process is quick and easy."
            : "Reilun toimitus ja nouto on nopeaa ja helppoa.",
          "Fill in your application": "Täytä hakemuksesi",
          "Customers make orders on Reilu's app or website.": "Asiakkaat tekevät tilauksen Reilun sovelluksessa tai verkkosivulla.",
          "Get a Free App": "Hanki ilmainen sovellus",
          "You will receive order from the customer on your phone and tablet": "Saat tilauksen asiakkaalta puhelimeesi ja tablettiin",
          "Sign a contract": "Allekirjoittaa sopimus",
          "You will recieve a call and an online contract. Review and sign."
            : "Saat puhelun ja online-sopimuksen. Tarkista ja allekirjoita.",
          "Make your customers Happy": "Tee asiakkaasi onnelliseksi",
          "Work with us to provide the same experience what they get at your restaurant"
            : "Työskentele kanssamme tarjotaksesi saman kokemuksen kuin he saavat ravintolassasi",
          "How can we help?": "Kuinka voimme auttaa?",
          "Let us know how we could make our service better and more suitable for your needs (info@reilukuljetus.fi"
            : "Kerro meille, kuinka voisimme tehdä palvelustamme entistä paremman ja tarpeisiisi sopivamman (info@reilukuljetus.fi",
          "Restaurant app": "Ravintolasovellus",
          "Our restaurant app keeps your orders in track. We will provide the equipment for you free of charge."
            : "Ravintolasovelluksemme pitää tilauksesi ajan tasalla. Toimitamme laitteet sinulle maksutta.",


          "Become a Reilu Driver and..": "Ryhdy Reilu Kuljettajaksi ja...",
          "When you are on the road, we are always there for you to help, advice and support when needed. Just message us on the app!"
            : "Kun olet tien päällä, olemme aina valmiina auttamaan, neuvomaan ja tukemaan tarvittaessa. Laita meille viestiä sovelluksessa!",
          "How can we help?": "Kuinka voimme auttaa?",
          "Restaurant app": "Ravintolasovellus",
          "Our restaurant app keeps your orders in track. We will provide the equipment for you free of charge."
            : "Ravintolasovelluksemme pitää tilauksesi ajan tasalla. Toimitamme laitteet sinulle maksutta.",

          "(Read more)": "(Lue lisää)",
          "(Show less)": "(Näytä vähemmän)",
          "Watch Now": "Katso nyt",


          // popup
          "Driver Registration Form": "Kuljettajan rekisteröintilomake",
          "First name": "Etunimi",
          "Mobile number": "Matkapuhelinnumero",
          "Your Vehicle": "Sinun ajoneuvosi",
          "Last Name": "Sukunimi",
          "E-Email": "Sähköposti",
          "Language": "Kieli",
          "Do you have a valid work permit?": "Onko sinulla voimassa oleva työlupa?",
          "Yes": "Joo",
          "No": "Ei",
          "Do you have a driving license?": "Onko sinulla ajokorttia?",
          "I've read and agree with the User": "Olen lukenut ja samaa mieltä Käyttäjän kanssa",

          "Restaurant Registration Form": "Ravintolan ilmoittautumislomake",
          "Restaurant Name": "Ravintolan nimi",
          "Number": "Määrä",
          "Business ID": "Y-tunnus",
          "Email": "Sähköposti",
          "I agree to the Reilu's": "Olen samaa mieltä Reilun kanssa",
          "privacy policy.": "Tietosuojakäytäntö.",

          // footer
          "Delivigo oy 2023 ALL Right reserved": "Delivigo oy 2023 KAIKKI Oikeudet pidätetään",
          "Privacy Policy": "Tietosuojakäytäntö",
          "Terms & Conditions": "käyttöehdot",

          // About
          "What we are?": "Mitä Reilu tekee?",
          "Reilu kuljetus is a Turku-based startup that brings the best of the local restaurants to your doorstep in a fair and premium manner. Behind the scenes,"
            : "Reilu kuljetus tarjoaa alustan, joka yhdistää sinut lempiravintoloihisi Turussa ja kuljettaa aterian kotiovellesi.",
          'Reilu is run by a team of professional enthusiasts who aim at making the food delivery service more ethical and sustainable. Our delivery partners are actually decision makers in Reilu’s model. Together with the best local restaurant partners, we deliver in a more ethical and responsible manner towards the environment. Above all, Reilu takes the safety of your data seriously and does not charge the customers any extra to enjoy the premium service. We are a fully approachable team and would always love to hear from you, find more about us [here.]'
            : 'Reilua pyörittää ammattiharrastajatiimi, jonka tavoitteena on tehdä ruoan toimituspalvelusta eettisempi ja kestävämpi. Toimituskumppanimme ovat itse asiassa päättäjiä Reilun mallissa. Toimitamme yhdessä parhaiden paikallisten ravintolakumppaneiden kanssa eettisemmin ja vastuullisemmin ympäristöä kohtaan. Ennen kaikkea Reilu suhtautuu tietojesi turvallisuuteen vakavasti, eikä veloita asiakkailta lisäveloitusta premium-palvelun nauttimisesta. Olemme täysin tavoitettavissa oleva tiimi ja haluaisimme aina kuulla sinusta. Lue lisää meistä [täältä.]',
          "Reilu is run by a team of professional enthusiasts who aim at making the food delivery service more ethical and sustainable. Our delivery partners are actually decision makers in Reilu’s model. Together with the best local restaurant partners, we deliver in a more ethical and responsible manner towards the environment. Above all, Reilu takes the safety of your data seriously and does not charge the customers any extra to enjoy the premium service. We are a fully approachable team and would always love to hear from you, find more about us"
            : "Reilua pyörittää ammattiharrastajatiimi, jonka tavoitteena on tehdä ruoan toimituspalvelusta eettisempi ja kestävämpi. Toimituskumppanimme ovat itse asiassa päättäjiä Reilun mallissa. Toimitamme yhdessä parhaiden paikallisten ravintolakumppaneiden kanssa eettisemmin ja vastuullisemmin ympäristöä kohtaan. Ennen kaikkea Reilu suhtautuu tietojesi turvallisuuteen vakavasti, eikä veloita asiakkailta lisäveloitusta premium-palvelun nauttimisesta. Olemme täysin tavoitettavissa oleva tiimi ja haluaisimme aina kuulla sinusta, lisätietoja meistä",

          "Milestones": "Virstanpylväät",
          "Before expanding our operations to other cities, we would like to improve our services to better match the needs of our customers. By 2023, we aim to start serving customers from other Finnish cities too. We would love our customers to share their views about our service and help us in co-creating a truly customer-centric service."
            : "Ennen kuin laajennamme toimintaamme muihin kaupunkeihin, haluamme parantaa palveluitamme vastaamaan paremmin asiakkaidemme tarpeita. Vuoteen 2023 mennessä pyrimme palvelemaan asiakkaita myös muista Suomen kaupungeista. Haluaisimme, että asiakkaamme jakavat näkemyksensä palvelustamme ja auttaisimme meitä luomaan aidosti asiakaslähtöistä palvelua.",
          "Expanding to two new cities in 2023": "Laajentuminen kahteen uuteen kaupunkiin vuonna 2023",
          "The Launch": "Laukaisu",
          "Reilu is TURKULAINEN. In the last few months, we have been grinding to make our services available to the good citizens of Turku. We have made fair contracts with both restaurant and delivery partners. We are done with our dry runs & beta testing, and we are all ready to hit the streets of Turku."
            : "Reilu on TURKULAINEN. Olemme viime kuukausina hioneet palveluitamme hyvien turkulaisten saataville. Olemme tehneet reilut sopimukset sekä ravintola- että toimituskumppaneiden kanssa. Kuivakäynnit & beta-testaukset ovat valmiit ja olemme kaikki valmiita lähtemään Turun kaduille.",
          "Expanding from food delivery to other goods": "Laajentuminen ruoan toimituksesta muihin tavaroihin",
          "Reilu is TURKULAINEN. In the last few months, we have been grinding to make our services available to the good citizens of Turku. We have made fair contracts with both restaurant and delivery partners. We are done with our dry runs & beta testing, and we are all ready to hit the streets of Turku."
            : "Reilu on TURKULAINEN. Olemme viime kuukausina hioneet palveluitamme hyvien turkulaisten saataville. Olemme tehneet reilut sopimukset sekä ravintola- että toimituskumppaneiden kanssa. Kuivakäynnit & beta-testaukset ovat valmiit ja olemme kaikki valmiita lähtemään Turun kaduille.",
          "Our Brilliant team is here to help": "Loistava tiimimme on täällä auttamassa",
          "Founder": "Perustaja",
          "Rufus Vinod is a Ph.D student and an entrepreneur. During Ph.D at university of Turku he started working as a courier partner in a delivery company. He felt the need of a delivery system that would provide an provide better wording conditions for the couriers and provide customers with a local and provides the customer a local platform.Reilu is a Finnish company and TURKU will always be our home."
            : "Rufus Vinod on tohtoriopiskelija ja yrittäjä. Tohtorintutkinnon aikana Turun yliopistossa hän aloitti työskentelyn kuriirikumppanina jakeluyrityksessä. Hän tunsi tarvetta jakelujärjestelmälle, joka tarjoaisi paremmat sanamuodot kuriireille ja tarjoaisi asiakkaille paikallisen ja asiakkaalle paikallisen alustan. Reilu on suomalainen yritys ja TURKU tulee aina olemaan kotimme.",
          "Lauri Koittola is a serial entrepreneur and a team coach, who ended up as a business partner through a common friend. In the beginning Lauri’s main responsibility was connections to restaurants and media. After launching the business the emphasis shifted to coaching the team with self-managing methods. Lauri is also responsible for the matters regarding cooperative business model."
            : "Lauri Koittola on sarjayrittäjä ja tiimivalmentaja, joka päätyi yhteisen ystävän kautta liikekumppaniksi. Alussa Laurin päävastuu oli yhteydet ravintoloihin ja mediaan. Liiketoiminnan käynnistämisen jälkeen painopiste siirtyi tiimin valmennukseen itsejohtamismenetelmin. Lauri vastaa myös osuustoiminnalliseen liiketoimintamalliin liittyvistä asioista.",

          "About Reilu": "Tietoja Reilusta",
          "Reilu kuljetus is Rufus’ brainchild. Rufus is a cancer researcher and a part-time courier who brings joy to hungry Turku citizens. While working as a courier, he realized how little the couriers were able to influence their working conditions. It’s about time things changed. He gathered a team of enthusiasts to create a customer-centric food delivery service which helps the small local businesses sustain while creating better working conditions for the delivery partners."
            : "Reilu on Turussa perustettu startup, joka toimittaa ravintolaruokaa kotiovellesi taatun reilusti. Osuuskuntapohjaisesti toimiva Reilu takaa oikeudenmukaiset korvaukset sekä ravintoloille että kuljettajille. Kuljettajamme eivät ole vain työmuurahaisia vaan osa yritystä – kirjaimellisesti. Osuuskunnan jäseninä he ovat yrityksen täysivaltaisia omistajia. Voit lukea lisää kuljettajista, täältä.",
          "Lauri was invited first to join and become a business partner in the company. He’s a natural people’s person making him an invaluable asset to the company who networks with the local restaurants and the media."
            : "Lauri kutsuttiin ensimmäisenä mukaan yritykseen liikekumppaniksi. Hän on luonnollinen ihminen, mikä tekee hänestä korvaamattoman voimavaran paikallisten ravintoloiden ja median kanssa verkostoituvalle yritykselle.",
          "We are expanding. We now have service designers who are working round the clock making the service as customer-oriented as possible. We look at our customers not just as someone who pay us for our service, but also someone who help us in developing our services further. With Reilu kuljetus, you are not just customers, you are co-creators."
            : "Laajennamme. Meillä on nyt palvelusuunnittelijat, jotka työskentelevät vuorokauden ympäri ja tekevät palvelusta mahdollisimman asiakaslähtöistä. Emme katso asiakkaitamme vain sellaiseksi, joka maksaa meille palvelustamme, vaan myös henkilönä, joka auttaa meitä kehittämään palveluitamme edelleen. Reilu kuljetuksen avulla et ole vain asiakkaita, olet myös yhteistyökumppaneita.",

          "Contact us": "Ota meihin yhteyttä",
          "Email address": "Sähköpostiosoite",
          "Subject": "Aihe",
          "Comments": "Kommentit",
          "Submit": "Lähetä",
          "Reilu kuljetus is Rufus’ brainchild. Rufus is a cancer researcher and a part-time courier who brings joy to Turku citizens. While working as a courier, he realized how little the couriers were able to influence their working conditions.It’s about time things changed. He gathered a team of enthusiasts to create a fair food delivery service which helps the small local businesses to sustain while creating better working conditions for the delivery partners and restaurants."
            : "Reilu kuljetus on Rufuksen aivotuote. Rufus on syöpätutkija ja osa-aikainen kuriiri, joka ilahduttaa turkulaisia. Kuriirina toimiessaan hän tajusi, kuinka vähän kuriirit pystyivät vaikuttamaan työoloihinsa. On aika muuttua. Hän kokosi ryhmän harrastajia luomaan reilun ruoan toimituspalvelun, joka auttaa paikallisia pieniä yrityksiä selviytymään ja luo samalla paremmat työolosuhteet toimituskumppaneille ja ravintoloihin.",
          "We are expanding. We now have service designers who are working round the clock making the service as friendly as possible. We look at our customers not just as someone who pay us for our service, but also someone who helps us in developing our services further. With Reilu kuljetus, you are not just customers, you are co-creators."
            : "Laajennamme. Meillä on nyt palvelusuunnittelijat, jotka työskentelevät ympäri vuorokauden ja tekevät palvelusta mahdollisimman ystävällistä. Emme katso asiakkaitamme vain sellaiseksi, joka maksaa meille palvelustamme, vaan myös sellaisena, joka auttaa meitä kehittämään palveluitamme edelleen. Reilu kuljetuksen avulla et ole vain asiakkaita, olet myös yhteistyökumppaneita.",
          "Do you want to contact us? Great, you can do it using the form below. We are happy to hear thoughts, development proposals and general feedback! Fair transport is for you, so your opinion is extremely important to us."
            : "Haluatko ottaa meihin yhteyttä? Hienoa, voit tehdä sen alla olevalla lomakkeella. Mielellämme kuulemme ajatuksia, kehitysehdotuksia ja yleistä palautetta! Reilu kuljetus on sinua varten, joten mielipiteesi on meille erittäin tärkeä.",
          "Name": "Nimi",
        },
      },
    },
    fallbackLng: "suomi",
    debug: true,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
