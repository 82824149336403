import React from "react";
import "../../css/Home/Footer.css";
import AppStore from "../../assets/Icons/AppStore.svg";
import GooglePlay from "../../assets/Icons/GooglePlay.svg";
import WhatScan from "../../assets/Icons/WhatScan.svg";
import IPhoneX from "../../assets/Image/IPhoneX.png";
import Footer2 from "../../components/Home/Footer2";
import { useTranslation } from "react-i18next";

const Footer = ({ check, setcheck }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="can_download_div mb-5">
        <div className=" container-fluid can_download_centerDiv ">

          <div className="row d-flex justify-content-center ">
            <div className="col-xl-5 col-lg-5 col-md-5 col-ms-12 col-sm-12 can_colmn">
              <div className="can_headingDiv">
                <h2 className="can_colmn_h2">{t("You can download the apps.")}</h2>
                <p className="can_colmn_p">
                  {t("Reilu is a Turku-based startup that aims to provide a solution for the customers to order food with good conscience.")}
                </p>
                <div className="can_respnsive_div">
                  <div className="work_download_app_div2">
                    <a href="https://www.reilukuljetus.fi">
                      <img src={AppStore} alt="" className="works_apps2" />
                    </a>
                    <a href="https://www.reilukuljetus.fi">
                      <img src={GooglePlay} alt="" className="works_apps2" />
                    </a>

                  </div>
                  <img src={WhatScan} alt="" className="whatapp_scaner" />
                </div>
                <br />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  ">
              <img src={IPhoneX} alt="" className="iphone_div" style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>
      <Footer2 check={check} setcheck={setcheck} />
    </>
  );
};

export default Footer;
